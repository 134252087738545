<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      v-loading="loading"
      :class="
        curStep === 2 && formData.TriggerType === '多波次流程画布'
          ? 'fullWidth'
          : 'commonWidth'
      "
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add" || saveType === "templateAdd"
              ? "新建运营计划"
              : saveType === "show"
              ? "查看运营计划"
              : "修改运营计划"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <!-- <el-button
            @click="showPre = true"
            round
            style="background: #4d4b58; color: #fff"
            >预览分群概貌</el-button
          > -->
          <el-button v-if="curStep > 1" type="primary" round @click="handlePre"
            >上一步</el-button
          >
          <el-button v-if="curStep < 4" type="primary" round @click="handleNext"
            >下一步</el-button
          >
          <el-button
            v-if="curStep === 4"
            type="primary"
            round
            :disabled="disabled"
            @click="handleSave(0)"
            :loading="saving"
            >保存</el-button
          >
          <el-button
            v-if="curStep === 4"
            type="primary"
            round
            :disabled="disabled"
            @click="publishConfirmShow = true"
            :loading="saving"
            >保存并发布</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
        >
          <div class="step-list">
            <div class="step-item" :class="{ ready: curStep === 1 }">
              <i class="iconfont icon-user-doublekx"></i>
              <div>
                <div class="title">客户筛选</div>
                <div class="desc">设置分群名称及数据更新方式</div>
              </div>
            </div>
            <i class="el-icon-arrow-right"></i>
            <div class="step-item" :class="{ ready: curStep === 2 }">
              <i class="iconfont icon-rili"></i>
              <div>
                <div class="title">触发规则</div>
                <div class="desc">定义分群条件和逻辑</div>
              </div>
            </div>
            <i
              class="el-icon-arrow-right"
              v-if="formData.TriggerType !== '多波次流程画布'"
            ></i>
            <div
              class="step-item"
              v-if="formData.TriggerType !== '多波次流程画布'"
              :class="{ ready: curStep === 3 }"
            >
              <i class="iconfont icon-neirong"></i>
              <div>
                <div class="title">渠道内容</div>
                <div class="desc">定义触达渠道和内容</div>
              </div>
            </div>
            <i class="el-icon-arrow-right"></i>
            <div class="step-item" :class="{ ready: curStep === 4 }">
              <i class="iconfont icon-neirong"></i>
              <div>
                <div class="title">目标设置</div>
                <div class="desc">定义转化目标与分析漏斗</div>
              </div>
            </div>
          </div>
          <div v-show="curStep === 1" class="step-1">
            <cond-card>
              <el-form-item
                label="运营计划名称"
                prop="Name"
                style="margin: 6px 0"
                label-width="94px"
              >
                <el-input
                  v-model="formData.Name"
                  placeholder="运营计划名称"
                  style="width: 440px"
                  :disabled="disabled || formJyMuster.NameStep1"
                ></el-input>
              </el-form-item>
            </cond-card>
            <cond-card title="客户筛选">
              <el-form-item
                label="选择客户分群"
                prop="CustomerGroup"
                label-width="94px"
              >
                <el-select
                  v-model="formData.CustomerGroup"
                  placeholder="选择或搜索一个客户分群"
                  style="width: 440px"
                  filterable
                  :disabled="disabled || formJyMuster.CustomerGroupStep1"
                >
                  <el-option
                    v-for="item in groupList"
                    :key="item._id"
                    :label="item.Name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </cond-card>
            <cond-card title="计划类型">
              <div class="plan-types">
                <radio-item
                  v-for="(item, index) in planTypes"
                  :key="index"
                  :detail="item"
                  :disabled="disabled || formJyMuster.TriggerTypeStep1"
                  :active="formData.TriggerType === item.name"
                  @change="handleTypeChange"
                ></radio-item>
              </div>
            </cond-card>
          </div>
          <div v-show="curStep === 2" class="step-2">
            <cond-dcds
              v-show="formData.TriggerType === '单次定时群发'"
              ref="dcds"
              :disabled="disabled"
              :formJyMuster="formJyMuster"

            ></cond-dcds>
            <cond-zqxqf
              v-show="formData.TriggerType === '周期性定时群发'"
              ref="zqxqf"
              :disabled="disabled"
              :formJyMuster="formJyMuster"
            ></cond-zqxqf>
            <cond-dctj
              v-show="formData.TriggerType === '单次触发'"
              ref="dctj"
              :disabled="disabled"
              :formJyMuster="formJyMuster"
            ></cond-dctj>
            <cond-cdsj
              v-show="formData.TriggerType === '单次触发'"
              ref="cdsj"
              :disabled="disabled"
              :formJyMuster="formJyMuster"
            ></cond-cdsj>
            <cond-dbc
              v-show="formData.TriggerType === '多波次流程画布'"
              :editStatus="saveType"
              :isShow="
                formData.TriggerType === '多波次流程画布' && curStep === 2
              "
              ref="dbc"
              :disabled="disabled"
              :formJyMuster="formJyMuster"
              :CustomerGroup="formData.CustomerGroup"
            ></cond-dbc>
          </div>
          <div v-show="curStep === 3" class="step-3">
            <cond-card
              title="触达渠道"
              v-if="formData.TriggerType !== '多波次流程画布'"
            >
              <el-form-item
                label="触达渠道"
                prop="MessageChannel"
                label-width="70px"
              >
                <el-cascader
                  v-model="formData.MessageChannel"
                  :options="MessageChannelOptions"
                  @change="handleChannelChange"
                  style="width: 320px"
                  :disabled="disabled || formJyMuster.MessageChannelStep3"
                ></el-cascader>
              </el-form-item>
            </cond-card>
            <send-people-select
              v-if="(formData.MessageChannel.indexOf('企业微信') !== -1)"
              :MessageChannel="formData.MessageChannel"
              :SenderChooseType.sync="formData.SenderChooseType"
              :SenderChooseList.sync="formData.SenderChooseList"
              :CustomerGroup="formData.CustomerGroup"
              :disabled="disabled || formJyMuster.CustomerGroupStep1"
            ></send-people-select>
            <cond-card
              title="触达内容"
              v-if="formData.TriggerType !== '多波次流程画布'"
            >
              <el-form-item
                label="触达内容"
                prop="MessageTemplate"
                label-width="70px"
              >
                <el-select
                  v-model="formData.MessageTemplate"
                  placeholder="请选择触达内容"
                  style="width: 320px"
                  filterable
                  :disabled="disabled || formJyMuster.MessageTemplateStep3"
                >
                  <el-option
                    v-for="item in MessageTemplateOptions"
                    :key="item._id"
                    :label="item.Name"
                    :value="item._id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </cond-card>
          </div>
          <div v-show="curStep === 4" class="step-4">
            <cond-path ref="path" :disabled="disabled" :formJyMuster="formJyMuster"></cond-path>
          </div>
        </el-form>
      </div>
    </el-drawer>

    <el-dialog title="发布确认" :visible.sync="publishConfirmShow" width="30%">
      <span
        >请确认所有人群、渠道、内容无误，确认发布？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="publishConfirmShow = false" type="text"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleSave(1)" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { apiGetGroups } from "@/api/group";
import {
  apiAddPlan,
  apiUpdatePlan,
  apiGetPlanDetail,
  apiGetTemplateDetail,
} from "@/api/plan";
import { apiGetTemplateListByChannel } from "@/api/template";
import radioItem from "@/components/radio-item";
import condCard from "@/components/cond-card";
import condDcds from "./con-dcds";
import condZqxqf from "./con-zqxqf";
import condDctj from "./con-dctj";
import condDbc from "./con-dbc";
import condCdsj from "./con-cdsj";
import condPath from "./con-path";
import sendPeopleSelect from "./send-people-select";
export default {
  components: {
    radioItem,
    condCard,
    condDcds,
    condZqxqf,
    condDctj,
    condDbc,
    condCdsj,
    condPath,
    sendPeopleSelect
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    templateId: null,
  },
  data() {
    return {
      curStep: 1,
      saving: false,
      loading: false,
      disabled: false,
      isSended: false,
      direction: "rtl",
      formData: {
        Name: "",
        CustomerGroup: "",
        TriggerType: "单次定时群发",
        MessageChannel: [],
        MessageTemplate: "",
        Status: "",
        SenderChooseType: '',
        SenderChooseList: ''
      },
      formJyMuster: {
        NameStep1: false,
        CustomerGroupStep1: false,
        TriggerTypeStep1: false,
        MessageChannelStep3: false,
        MessageTemplateStep3: false,
        CampaignMemberStatusStep4: false,
        CustomerBehaviorExecuteTimeStep4: false,
        CustomerBehaviorsStep4: false,
        SystemEventsStep4: false,

        StartTimeStep2Dcds: false,

        StartTimeStep2Zqxqf: false,
        RecurringStep2Zqxqf: false,
        RecurringEndStep2Zqxqf: false,
        
        StartEndTimeStep2Dctj: false,
        TriggerTimeStep2Dctj: false,
        FilterItemsStep2Dctj: false,
        ExecuteTimeTypeStep2Dctj: false,

        StartEndTimeStep2Dbc: false,
        handleStep2Dbc: false,
        FlowsStep2Dbc: false,
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入运营计划名称",
          trigger: "blur",
        },
        CustomerGroup: {
          required: true,
          message: "请选择客户分群",
          trigger: "change",
        },
        MessageChannel: {
          required: true,
          message: "请选择触达渠道",
          trigger: "change",
        },
        MessageTemplate: {
          required: true,
          message: "请选择触达内容",
          trigger: "change",
        }
      },
      groupList: [],
      planTypes: [
        {
          icon: "iconfont icon-clock",
          name: "单次定时群发",
          desc: "创建一次性执行的计划",
        },
        {
          icon: "iconfont icon-rili",
          name: "周期性定时群发",
          desc: "创建重复执行的计划",
        },
        {
          icon: "iconfont icon-lightning",
          name: "单次触发",
          desc: "创建一次性的触发型计划",
        },
        {
          icon: "iconfont icon-dashbaord",
          name: "多波次流程画布",
          desc: "使用可视化多流程来创建计划",
        },
      ],
      MessageTemplateOptions: [],
      publishConfirmShow: false,
      firstFlag: false
    };
  },
  computed: {
    ...mapGetters({
      allChannels: "common/allChannels",
      typesChannels: "common/typesChannels",
    }),
    MessageChannelOptions() {
      const channels = this.allChannels;
      const map = {};
      const result = [];
      (this.typesChannels || []).forEach((typeName) => {
        map[typeName] = {};
      });

      channels.forEach((item) => {
        if (item.IsActive == 0) {
          return;
        }
        if (
          map[item.Type] === undefined ||
          Object.keys(map[item.Type]).length === 0
        ) {
          map[item.Type] = {
            label: item.Type,
            value: item.Type,
            children: [],
          };
        }
        map[item.Type].children.push({
          label: item.Name,
          value: item._id,
        });
      });
      Object.keys(map).forEach((key) => {
        result.push(map[key]);
      });
      return result;
    },
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getPlanDetail();
      } else {
        this.resetForm();
      }
    },
    "formData.MessageChannel": {
      handler(val) {
        if (this.firstFlag) {
          this.firstFlag = !this.firstFlag
          return
        }
        if (val && val[0] !== '企业微信') {
          this.formData.SenderChooseType = ''
          this.formData.SenderChooseList = ''
          return
        }
        this.formData.SenderChooseType = '通过最后一次添加员工发送'
        this.formData.SenderChooseList = ''
      }
    },
    "formData.TriggerType": {
      handler(val) {
        if (val === '多波次流程画布') {
          this.formData.SenderChooseType = ''
          this.formData.SenderChooseList = ''
        }
      }
    },
    "formData.CustomerGroup": {
      handler(nVal, oVal) {
        if (oVal) {
          this.formData.SenderChooseList = ''
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getAllChannels: "common/getAllChannels",
    }),
    handlePre() {
      if (this.curStep === 4) {
        if (this.formData.TriggerType === "多波次流程画布") {
          this.curStep -= 2;
        } else {
          this.curStep--;
        }
      } else {
        this.curStep--;
      }
    },
    handleNext() {
      if (this.curStep === 1) {
        this.$refs.form.validateField(["Name"], (nameErr) => {
          this.$refs.form.validateField(["CustomerGroup"], (groupErr) => {
            if (!nameErr && !groupErr) {
              this.curStep++;
            }
          });
        });
      } else if (this.curStep === 2) {
        if (this.formData.TriggerType === "单次定时群发") {
          if (this.$refs.dcds.formData.StartTime === "") {
            this.$message.error("请选择群发时间");
          } else {
            this.curStep++;
          }
        }
        if (this.formData.TriggerType === "周期性定时群发") {
          if (this.$refs.zqxqf.formData.StartTime === "") {
            this.$message.error("请选择群发时间");
          } else {
            if (this.$refs.zqxqf.formData.RecurringEndType === "AfterCertainDate") {
              if (!this.$refs.zqxqf.formData.EndTime) {
                this.$message.error("请选择结束时间");
              } else {
                this.curStep++;
              }
            }else{
              this.curStep++;
            }
          }
        }
        if (this.formData.TriggerType === "单次触发") {
          if (this.$refs.dctj.StartEndTime.length  === 0) {
            this.$message.error("请选择日期");
          }else{
            if (this.$refs.dctj.formData.BehaviorFilterGroups[0].FilterItems.length === 0) {
              this.$message.error("请选择触发条件");
            }else{
              this.curStep++;
            }
          }
        }
        if (this.formData.TriggerType === "多波次流程画布") {
          if (this.$refs.dbc.StartEndTime.length  === 0) {
            this.$message.error("请选择日期");
          } else {
            if (this.$refs.dbc.Flows.length < 2) {
              this.$message.error("流程需要最小长度为2");
            }else{
              this.curStep += 2;
            }
          }
        }
      } else if (this.curStep === 3) {
        if (this.formData.MessageChannel.indexOf('企业微信') !== -1) {
          if (!this.formData.SenderChooseType) {
            this.$message.error("请设置发送人员选择类型");
            return
          }
          if (this.formData.SenderChooseType === "指定人员发送" && !this.formData.SenderChooseList) {
            this.$message.error("请指定发送人员");
            return
          }
        }
        this.$refs.form.validateField(["MessageChannel"], (MessageChannel) => {
          this.$refs.form.validateField(
            ["MessageTemplate"],
            (MessageTemplate) => {
              if (!MessageChannel && !MessageTemplate) {
                this.curStep++;
              }
            }
          );
        });
      } else {
        if (this.next2Disabled()) {
          return;
        }
        this.curStep++;
      }
    },
    next2Disabled() {
      let disabled = false;
      if (this.curStep === 2 && this.formData.TriggerType === "单次触发") {
        if (this.$refs.dctj) {
          disabled = !this.$refs.dctj.validate();
        }
      }
      return disabled;
    },
    handleTypeChange(plan) {
      this.formData.TriggerType = plan.name;
    },
    handleChannelChange(channel) {
      this.formData.MessageTemplate = "";
      this.getTemplates(channel[1]);
    },
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave(type) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (type === 1) {
            this.publishConfirmShow = false;
          }
          this.submitForm(type);
        }
      });
    },
    submitForm(type) {
      const {
        Name,
        CustomerGroup,
        TriggerType,
        MessageChannel,
        MessageTemplate,
        SenderChooseType,
        SenderChooseList
      } = this.formData;
      const param = {
        Name,
        CustomerGroup,
        TriggerType,
        Status: type === 0 ? "草稿" : "已发布",
        Flows: [],
        StatusConfigItems: this.$refs.path.getResult(),
      };
      if (param.StatusConfigItems === false) {
        return;
      }
      if (TriggerType === "单次定时群发") {
        param.Flows = [{
          ...this.$refs.dcds.getResult(), 
          SenderChooseType,
          SenderChooseList
        }];
      } else if (TriggerType === "周期性定时群发") {
        param.Flows = [{
          ...this.$refs.zqxqf.getResult(),
          SenderChooseType,
          SenderChooseList
        }];
      } else if (TriggerType === "单次触发") {
        param.Flows = [
          {
            ...this.$refs.dctj.getResult(),
            ...this.$refs.cdsj.getResult(),
            SenderChooseType,
            SenderChooseList
          },
        ];
      } else if (TriggerType === "多波次流程画布") {
        param.Flows = this.$refs.dbc.getResult();
      }
      if (TriggerType === "多波次流程画布") {
        // 多波次每个流程中有自己多渠道和内容，所以用不到外层的渠道和内容，并且页面上也不会显示
      } else {
        param.Flows.forEach((item) => {
          Object.assign(item, {
            MessageChannelType: MessageChannel[0],
            MessageChannel: MessageChannel[1],
            MessageTemplate: MessageTemplate,
          });
        });
      }

      this.saving = true;
      let prom;
      if (this.saveType === "add" || this.saveType === "templateAdd") {
        prom = apiAddPlan(param);
      } else {
        prom = apiUpdatePlan(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.curStep = 1;
      this.disabled = false;
      this.formData = {
        Name: "",
        CustomerGroup: "",
        TriggerType: "单次定时群发",
        MessageChannel: [],
        MessageTemplate: "",
        Status: "",
        SenderChooseType: "",
        SenderChooseList: ""
      };
      this.formJyMuster = {
        NameStep1: false,
        CustomerGroupStep1: false,
        TriggerTypeStep1: false,
        MessageChannelStep3: false,
        MessageTemplateStep3: false,
        CampaignMemberStatusStep4: false,
        CustomerBehaviorExecuteTimeStep4: false,
        CustomerBehaviorsStep4: false,
        SystemEventsStep4: false,

        StartTimeStep2Dcds: false,

        StartTimeStep2Zqxqf: false,
        RecurringStep2Zqxqf: false,
        RecurringEndStep2Zqxqf: false,
        
        StartEndTimeStep2Dctj: false,
        TriggerTimeStep2Dctj: false,
        FilterItemsStep2Dctj: false,
        ExecuteTimeTypeStep2Dctj: false,

        StartEndTimeStep2Dbc: false,
        handleStep2Dbc: false,
        FlowsStep2Dbc: false,
        isPause: false,
      },
      this.$refs.dcds.resetForm();
      this.$refs.zqxqf.resetForm();
      this.$refs.dctj.resetForm();
      this.$refs.cdsj.resetForm();
      this.$refs.dbc.resetForm();
      this.$refs.path.resetForm();
      setTimeout(() => {
        this.$refs.form.clearValidate();
      }, 0);
    },
    getGroupList() {
      const param = {
        offset: 0,
        limit: 10000,
      };
      apiGetGroups(param).then((res) => {
        this.groupList = res.customerGroups;
      });
    },
    getTemplates(channelId) {
      apiGetTemplateListByChannel(channelId).then((res) => {
        this.MessageTemplateOptions = res.templates || [];
      });
    },
    getPlanDetail() {
      this.disabled = false;
      if (
        this.saveType === "edit" ||
        this.saveType === "templateAdd" ||
        this.saveType === "show"
      ) {
        this.loading = true;
        let req;
        if (this.saveType === "edit" || this.saveType === "show") {
          // 编辑
          req = apiGetPlanDetail(this.detail._id);
        } else {
          // 获取模板详情
          req = apiGetTemplateDetail(this.templateId);
        }
        req
          .then((res) => {
            this.firstFlag = true
            if (this.saveType === "templateAdd") {
              this.disabled = false;
            } else if (this.saveType === "show") {
              this.disabled = true;
            } else {
              this.disabled = res.Status === "已发布" ? true : false;
            }
            this.isSended = res.IsSended;
            const flowItem = res.Flows[0] || {};

            const initData = {
              Name: res.Name,
              CustomerGroup: res.CustomerGroup,
              TriggerType: res.TriggerType,
              Status: res.Status,
              MessageTemplate: flowItem.MessageTemplate,
              MessageChannel: [
                flowItem.MessageChannelType,
                flowItem.MessageChannel,
              ],
              SenderChooseType: res.SenderChooseType,
              SenderChooseList: res.SenderChooseList
            };
            if (res.TriggerType === "单次定时群发") {
              this.$refs.dcds.initForm(flowItem);
              initData.SenderChooseType = flowItem.SenderChooseType
              initData.SenderChooseList = flowItem.SenderChooseList
            } else if (res.TriggerType === "周期性定时群发") {
              this.$refs.zqxqf.initForm(flowItem);
              initData.SenderChooseType = flowItem.SenderChooseType
              initData.SenderChooseList = flowItem.SenderChooseList
            } else if (res.TriggerType === "单次触发") {
              this.$refs.dctj.initForm(flowItem);
              this.$refs.cdsj.initForm(flowItem);
              initData.SenderChooseType = flowItem.SenderChooseType
              initData.SenderChooseList = flowItem.SenderChooseList
            } else if (res.TriggerType === "多波次流程画布") {
              this.$refs.dbc.initForm(res.Flows || [], res.StartTime, res.EndTime);
            }
            this.$refs.path.initForm(res.StatusConfigItems || []);
            Object.assign(this.formData, initData);
            this.getTemplates(flowItem.MessageChannel);
            //已暂停 部分输入框限制
            if(initData.Status === '已暂停'){
              this.setFormInput(initData);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    setFormInput(data){
      Object.assign(this.formJyMuster, 
        {
          NameStep1: false,
          CustomerGroupStep1: true,
          TriggerTypeStep1: true,
          MessageChannelStep3: true,
          MessageTemplateStep3: false,
          CampaignMemberStatusStep4: false,
          CustomerBehaviorExecuteTimeStep4: false,
          CustomerBehaviorsStep4: false,
          SystemEventsStep4: false,
          isPause: true
        });
      switch (data.TriggerType) {
        case "单次定时群发":
          Object.assign(this.formJyMuster, 
          {
            StartTimeStep2Dcds: false,
          });
          break;
        case "周期性定时群发":
          Object.assign(this.formJyMuster, 
          {
            StartTimeStep2Zqxqf: this.isSended,//moment().isAfter(this.$refs.zqxqf.formData.StartTime),
            RecurringStep2Zqxqf: false,
            RecurringEndStep2Zqxqf: false,
            CampaignMemberStatusStep4: this.isSended,//moment().isAfter(this.$refs.zqxqf.formData.StartTime),
            CustomerBehaviorExecuteTimeStep4: this.isSended,//moment().isAfter(this.$refs.zqxqf.formData.StartTime),
            CustomerBehaviorsStep4: this.isSended,//moment().isAfter(this.$refs.zqxqf.formData.StartTime),
            SystemEventsStep4: this.isSended,
            isPause: true
          });
          break;
        case "单次触发":
          Object.assign(this.formJyMuster, 
          {
            StartEndTimeStep2Dctj: false,
            TriggerTimeStep2Dctj: false,
            FilterItemsStep2Dctj: false,
            ExecuteTimeTypeStep2Dctj: false,
            isPause: true
          });
          break;
        case "多波次流程画布":
          Object.assign(this.formJyMuster, 
          {
            StartEndTimeStep2Dbc: this.isSended,//moment().isAfter(this.$refs.dbc.StartEndTime[0]),
            handleStep2Dbc: true,
            FlowsStep2Dbc: true,

            CampaignMemberStatusStep4: this.isSended,//moment().isAfter(this.$refs.dbc.StartEndTime[0]),
            CustomerBehaviorExecuteTimeStep4: this.isSended,//moment().isAfter(this.$refs.dbc.StartEndTime[0]),
            CustomerBehaviorsStep4: this.isSended,//moment().isAfter(this.$refs.dbc.StartEndTime[0]),
            SystemEventsStep4: this.isSended,
            isPause: true
          });
          break;
      }
    }
  },
  mounted() {
    this.getGroupList();
    this.getAllChannels();
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  .commonWidth {
    /deep/ .el-drawer {
      width: 72% !important;
    }
  }
  .fullWidth {
    /deep/ .el-drawer {
      width: 100% !important;
    }
  }
  /deep/ .el-drawer {
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .step-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
    .step-item {
      display: flex;
      align-items: center;
      width: 230px;
      padding: 10px 12px 10px 20px;
      background: #fff;
      border: 1px solid #b7b6b9;
      border-radius: 6px;
      &.ready {
        color: #19b977;
        border-color: #19b977;
        i {
          color: #19b977;
        }
      }
      > i {
        width: 24px;
        margin-right: 12px;
        font-size: 24px;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }
      .desc {
        font-size: 12px;
        line-height: 19px;
      }
    }
    > i {
      font-size: 16px;
      font-weight: bold;
      margin: 0 22px;
    }
  }
  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
}
</style>
