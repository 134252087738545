import api from "../Axios";

// 获取消息模板列表
export const apiGetTemplateList = (params) =>
  api.get(`/message-templates`, { params });

// 通过渠道id获取消息模板列表
export const apiGetTemplateListByChannel = (channelId) =>
  api.get(`/message-templates/${channelId}`);

// 获取消息模板详情
export const apiGetTemplateDetail = (id) => api.get(`/message-template/${id}`);

// 删除消息模板
export const apiDeleteTemplate = (id) => api.delete(`/message-template/${id}`);

// 创建消息模板
export const apiAddTemplate = (params) => api.post(`/message-template`, params);

// 更新消息模板
export const apiUpdateTemplate = (id, params) =>
  api.put(`/message-template/${id}`, params);

// 测试消息模板
export const apiTestTemplate = (id, params) =>
  api.get(`/message-template/${id}/try`, { params });

// 获取某渠道下的消息模板列表(公众号下素材列表) params: { type:  image、video、voice、news, offset: 0, limit: 1000}
export const apiGetMediaList = (id, params) =>
  api.get(`/media/${id}`, { params });

// 获取微信公众号消息模板列表
export const apiGetWechatMessageList = (channelId) =>
  api.get(`/wechat/message/${channelId}`);

// 创建消息模板
export const apiGetyiqixiucode = () => api.get(`/yiqixiu/code`);
