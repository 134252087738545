<!-- 发送人选择 -->
<template>
  <cond-card title="发送人选择">
    <el-form-item>
      <el-radio-group v-model="mySenderChooseType" :disabled="disabled">
        <el-radio
          v-for="item in sendPeopleTypeList"
          :label="item.value"
          :key="item.value"
          :disabled="disabled"
          >{{ item.label }}</el-radio
        >
      </el-radio-group>
    </el-form-item>
    <!-- <el-form-item label="标签名称">
      <el-select placeholder="请选择标签名称" style="width: 320px"></el-select>
    </el-form-item> -->
    <el-form-item label="指定人员" v-if="mySenderChooseType === '指定人员发送'">
      <el-select
        placeholder="请选择指定人员"
        style="width: 100%"
        multiple
        v-model="mySenderChooseList"
        :disabled="disabled"
      >
        <el-option v-for="item in pickList" :key="item.UserId" :value="item.UserId" :label="item.UserName"></el-option>
      </el-select>
    </el-form-item>
  </cond-card>
</template>

<script>
import condCard from "@/components/cond-card";
import { apiGetSenderList } from "@/api/plan";
import { mapGetters } from "vuex";
export default {
  name: "sendPeopleSelect",
  components: {
    condCard,
  },
  props: {
    SenderChooseType: {
      default: () => "通过最后一次添加员工发送"
    },
    SenderChooseList: {
      default: () => ""
    },
    MessageChannel: {
      default: () => ""
    },
    CustomerGroup: {
      default: () => ""
    },
    Flows: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      mySenderChooseType: this.SenderChooseType,
      mySenderChooseList: this.SenderChooseList ? this.SenderChooseList.split(',') : [],
      sendPeopleTypeList: [
        // {
        //   label: "通过标签指定人员发送",
        //   value: "通过标签指定人员发送",
        // },
        {
          label: "通过最后一次添加员工发送",
          value: "通过最后一次添加员工发送",
        },
        {
          label: "全部关联人员发送",
          value: "全部关联人员发送",
        },
        {
          label: "指定人员发送",
          value: "指定人员发送",
        },
      ],
      pickList: [],
      firstFlag: true
    };
  },
  computed: {
    ...mapGetters({
      allChannels: "common/allChannels",
    }),
  },
  watch: {
    SenderChooseType(val) {
      this.mySenderChooseType = val
      if (!val || val === "全部关联人员发送" || val === '通过最后一次添加员工发送') {
        this.mySenderChooseList = []
      }
    },
    SenderChooseList(val) {
      this.mySenderChooseList = this.SenderChooseList ? this.SenderChooseList.split(',') : []
    },
    mySenderChooseType(val) {
      this.$emit('update:SenderChooseType', val)
    },
    mySenderChooseList(val) {
      if (val.join(',') !== this.SenderChooseList) {
        this.$emit('update:SenderChooseList', val.join(','))
      }
    },
    MessageChannel() {
      this.getPickList()
    },
    CustomerGroup() {
      this.getPickList()
    },
    Flows() {
      this.getPickList()
      if(this.firstFlag) {
        this.firstFlag = !this.firstFlag
        return
      }
      this.mySenderChooseType = '通过最后一次添加员工发送'
    }
  },
  methods: {
    getPickList() {
      this.pickList = []
      if (this.MessageChannel[1] && this.CustomerGroup) {
        apiGetSenderList({
          MessageChannelId: (() => {
            for(const item of this.allChannels) {
              if (item._id == this.MessageChannel[1]) {
                return item.AppID
              }
            }
            return undefined
          })(),
          CustomerGroupId: this.CustomerGroup,
          Flows: this.Flows
        }).then((res) => {
          this.pickList = res
        })
      }
    }
  },
  created() {
    this.getPickList()
  },
  mounted() {},
};
</script>
<style></style>
