<!-- 单次定时群发 -->
<template>
  <cond-card title="单次定时群发">
    <el-form-item label="群发时间" prop="StartTime">
      <el-date-picker
        v-model="formData.StartTime"
        type="datetime"
        style="width: 320px"
        placeholder="请选择单次群发时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :disabled="disabled || formJyMuster.StartTimeStep2"
      >
      </el-date-picker>
    </el-form-item>
    <cond-cdxz v-if="showCxdz"></cond-cdxz>
  </cond-card>
</template>

<script>
import condCard from "@/components/cond-card";
import condCdxz from "./con-cdxz";
export default {
  components: {
    condCard,
    condCdxz,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showCxdz: {
      type: Boolean,
      default: true,
    },
    formJyMuster: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      formData: {
        StartTime: "",
      },
    };
  },
  methods: {
    initForm(data = {}) {
      Object.keys(data).forEach((key) => {
        if (this.formData[key] !== undefined && data[key] !== undefined) {
          this.formData[key] = data[key];
        }
      });
    },
    resetForm() {
      this.formData = {
        StartTime: "",
      };
    },
    getResult() {
      return {
        ...this.formData,
      };
    },
  },
};
</script>

<style lang="less" scoped></style>
