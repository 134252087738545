<!-- 单次触发 -->
<template>
  <cond-card title="单次触发">
    <el-form-item
      v-if="model === 'group'"
      label="触发起止日期"
      prop="StartEndTime"
      label-width="84px"
    >
      <el-date-picker
        v-model="StartEndTime"
        type="datetimerange"
        style="width: 400px"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :disabled="disabled || formJyMuster.StartEndTimeStep2Dctj"
      >
      </el-date-picker>
      <div class="error-msg">{{ !StartEndTime.length ? "选择时间" : "" }}</div>
    </el-form-item>
    <el-form-item v-if="model === 'flow'">
      <div class="delay-wrap">
        <span>在前一流程完成后</span>
        <el-select
          v-model="formData.TriggerTimeQuantity"
          style="width: 125px; margin-right: 14px"
          :disabled="disabled || formJyMuster.TriggerTimeStep2Dctj || StartEndTimeStep2Dbc"
        >
          <el-option
            v-for="(item, index) in quantitys"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="formData.TriggerTimeUnit"
          style="width: 125px; margin-right: 14px"
          :disabled="disabled || formJyMuster.TriggerTimeStep2Dctj || StartEndTimeStep2Dbc"
        >
          <el-option label="分" value="分"> </el-option>
          <el-option label="小时" value="小时"> </el-option>
          <el-option label="天" value="天"> </el-option>
        </el-select>
        <span>内</span>
      </div>
    </el-form-item>
    <el-form-item label="触发条件" label-width="84px">
      <cond-item-wrap
        placeholder="客户行为"
        :isEmpty="formData.BehaviorFilterGroups[0].FilterItems.length === 0"
      >
        <cond-item
          v-for="(item, index) in formData.BehaviorFilterGroups[0].FilterItems"
          :key="item.id"
          :ref="item.id"
          :detail="item"
          :index="index"
          :fields="customerBehaFields"
          @remove="handleRemoveCondCustBeha"
          :disabled="disabled || formJyMuster.FilterItemsStep2Dctj"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled || formJyMuster.FilterItemsStep2Dctj"
            type="text"
            @click="handleAddCondCustBeha"
            >+ 添加条件</el-button
          >
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        placeholder="车辆行为"
        :isEmpty="formData.BehaviorFilterGroups[1].FilterItems.length === 0"
      >
        <cond-item
          v-for="(item, index) in formData.BehaviorFilterGroups[1].FilterItems"
          :key="item.id"
          :ref="item.id"
          :detail="item"
          :index="formData.BehaviorFilterGroups[0].FilterItems.length + index"
          :fields="carBehaFields"
          @remove="handleRemoveCondCarBeha"
          :disabled="disabled || formJyMuster.FilterItemsStep2Dctj"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled || formJyMuster.FilterItemsStep2Dctj"
            type="text"
            @click="handleAddCondCarBeha"
            >+ 添加条件</el-button
          >
        </div>
      </cond-item-wrap>
      <div class="cond-combination">
        <div class="content">
          <el-form-item
            label="筛选条件要求"
            label-width="84px"
            prop="BehaviorConditionType"
          >
            <el-select
              v-model="formData.BehaviorConditionType"
              :disabled="disabled || formJyMuster.FilterItemsStep2Dctj"
              style="width: 200px"
            >
              <el-option
                label="同时满足所有条件（AND）"
                value="AND"
              ></el-option>
              <el-option label="满足其中任一条件（OR）" value="OR"></el-option>
              <el-option label="自定义逻辑" value="Custom"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="筛选条件逻辑"
            label-width="84px"
            prop="BehaviorConditionLogic"
          >
            <el-input
              v-model="formData.BehaviorConditionLogic"
              placeholder="请输入筛选条件逻辑"
              :disabled="
                formData.BehaviorConditionType !== 'Custom' || disabled  || formJyMuster.FilterItemsStep2Dctj"
            ></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="error-msg">
        {{
          formData.BehaviorFilterGroups[0].FilterItems.length === 0 &&
          formData.BehaviorFilterGroups[1].FilterItems.length === 0
            ? "请设置一个条件"
            : ""
        }}
      </div>
    </el-form-item>
    <cond-cdxz v-if="showCxdz"></cond-cdxz>
  </cond-card>
</template>

<script>
import { mapGetters } from "vuex";
import condCard from "@/components/cond-card";
import condItemWrap from "@/components/cond-item-wrap";
import condItem from "./cond-item";
import util from "@/util";
import condCdxz from "./con-cdxz";
export default {
  components: {
    condCard,
    condItemWrap,
    condItem,
    condCdxz,
  },
  props: {
    // group 模式出现的是【触发岂止日期】，对应的字段是 StartTime、EndTime
    // flow 模式出现的是【在前一流程完成后】，对应的字段是 TriggerTimeQuantity、TriggerTimeUnit
    model: {
      type: String,
      default: "group",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCxdz: {
      type: Boolean,
      default: true,
    },
    formJyMuster: {
      type: Object,
      default: () => {
        return {
        StartEndTimeStep2Dctj: false,
        TriggerTimeStep2Dctj: false,
        FilterItemsStep2Dctj: false,
        }
      },
    },
    StartEndTimeStep2Dbc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      StartEndTime: [],
      quantitys: [],
      formData: {
        StartTime: "",
        EndTime: "",
        TriggerTimeUnit: "小时",
        TriggerTimeQuantity: "1",
        BehaviorConditionType: "AND",
        BehaviorConditionLogic: "",
        BehaviorFilterGroups: [
          {
            FilterGroup: "人行为",
            ObjectAPIName: "CustomerBehavior",
            FilterItems: [],
          },
          {
            FilterGroup: "车行为",
            ObjectAPIName: "CarBehavior",
            FilterItems: [],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      customerBehavior: "common/customerBehavior",
      carBehavior: "common/carBehavior",
    }),
    customerBehaFields() {
      const behavior =
        this.customerBehavior.find((item) => item.APIName === "Behavior") || {};
      return behavior.PicklistOptions || [];
    },
    carBehaFields() {
      const behavior =
        this.carBehavior.find((item) => item.APIName === "Behavior") || {};
      return behavior.PicklistOptions || [];
    },
    filterTxt() {
      const { formData } = this;

      if (formData.BehaviorConditionType === "Custom") {
        return formData.BehaviorConditionLogic;
      }
      const count =
        formData.BehaviorFilterGroups[0].FilterItems.length +
        formData.BehaviorFilterGroups[1].FilterItems.length;
      let result = "";
      for (let i = 1; i <= count; i++) {
        result += i;
        result += i === count ? "" : ` ${formData.BehaviorConditionType} `;
      }
      return result;
    },
  },
  watch: {
    filterTxt(newVal) {
      this.formData.BehaviorConditionLogic = newVal;
    },
  },
  methods: {
    handleAddCondCustBeha() {
      this.formData.BehaviorFilterGroups[0].FilterItems.push({
        id: util.randomString(6),
        Operator: "",
        Value: "",
      });
    },
    handleAddCondCarBeha() {
      this.formData.BehaviorFilterGroups[1].FilterItems.push({
        id: util.randomString(6),
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCondCustBeha(detail) {
      const index =
        this.formData.BehaviorFilterGroups[0].FilterItems.indexOf(detail);
      this.formData.BehaviorFilterGroups[0].FilterItems.splice(index, 1);
    },
    handleRemoveCondCarBeha(detail) {
      const index =
        this.formData.BehaviorFilterGroups[1].FilterItems.indexOf(detail);
      this.formData.BehaviorFilterGroups[1].FilterItems.splice(index, 1);
    },
    initForm(data = {}) {
      const { BehaviorFilterGroups = [] } = data;
      const custGroup =
        BehaviorFilterGroups.find((item) => item.FilterGroup === "人行为") ||
        {};
      const carGroup =
        BehaviorFilterGroups.find((item) => item.FilterGroup === "车行为") ||
        {};

      Object.keys(data).forEach((key) => {
        if (
          this.formData[key] !== undefined &&
          data[key] !== undefined &&
          key !== "BehaviorFilterGroups"
        ) {
          this.formData[key] = data[key];
        }
      });
      if (data.StartTime && data.EndTime) {
        this.StartEndTime = [data.StartTime, data.EndTime];
      } else {
        this.StartEndTime = [];
      }

      this.formData.BehaviorFilterGroups = [
        {
          FilterGroup: "人行为",
          ObjectAPIName: "CustomerBehavior",
          FilterItems: custGroup.FilterItems || [],
        },
        {
          FilterGroup: "车行为",
          ObjectAPIName: "CarBehavior",
          FilterItems: carGroup.FilterItems || [],
        },
      ];
    },
    resetForm() {
      this.StartEndTime = [];
      this.formData = {
        StartTime: "",
        EndTime: "",
        TriggerTimeUnit: "小时",
        TriggerTimeQuantity: "1",
        ExecuteTimeType: "延迟",
        ExecuteTimeUnit: "分",
        ExecuteTimeQuantity: 30,
        BehaviorConditionType: "AND",
        BehaviorConditionLogic: "",
        BehaviorFilterGroups: [
          {
            FilterGroup: "人行为",
            ObjectAPIName: "CustomerBehavior",
            FilterItems: [],
          },
          {
            FilterGroup: "车行为",
            ObjectAPIName: "CarBehavior",
            FilterItems: [],
          },
        ],
      };
    },
    getResult() {
      const param = {
        ...this.formData,
        StartTime: this.StartEndTime[0],
        EndTime: this.StartEndTime[1],
        BehaviorFilterGroups: [],
      };
      if (this.model === "group") {
        delete param.TriggerTimeUnit;
        delete param.TriggerTimeQuantity;
      } else if (this.model === "flow") {
        delete param.StartTime;
        delete param.EndTime;
        delete param.ExecuteTimeType;
        delete param.ExecuteTimeUnit;
        delete param.ExecuteTimeQuantity;
      }

      if (this.formData.BehaviorFilterGroups[0].FilterItems.length > 0) {
        param.BehaviorFilterGroups.push(this.formData.BehaviorFilterGroups[0]);
      }
      if (this.formData.BehaviorFilterGroups[1].FilterItems.length > 0) {
        param.BehaviorFilterGroups.push(this.formData.BehaviorFilterGroups[1]);
      }

      return param;
    },
    validate() {
      const validList = [
        ...this.formData.BehaviorFilterGroups[0].FilterItems,
        ...this.formData.BehaviorFilterGroups[1].FilterItems,
      ];
      let valid = true; // 是否验证通过，默认通过
      validList.forEach((item) => {
        let row = this.$refs[item.id] || [];
        row = row[0];
        if (row) {
          valid = row.validate() === false ? false : valid;
        }
      });
      if (!validList.length) {
        valid = false;
      }
      if (this.model === "group") {
        if (!this.StartEndTime.length) {
          valid = false;
        }
      }

      return valid;
    },
  },
  mounted() {
    const quantitys = [];
    for (let i = 1; i <= 60; i++) {
      quantitys.push({
        label: i,
        value: i,
      });
    }
    this.quantitys = quantitys;
  },
};
</script>

<style lang="less" scoped>
.cond-combination {
  .content {
    padding: 20px;
    padding-bottom: 6px;
    background: #f6f7f8;
    border-radius: 6px;
  }
}
.delay-wrap {
  margin-top: 20px;
  > span {
    margin-right: 10px;
    font-size: 12px;
  }
}

.error-msg {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>
