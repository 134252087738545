<!-- 单次触发 -->
<template>
  <cond-card title="触发条件">
    <el-form-item label="群发频率" label-width="84px" class="frequency">
      <el-select
        v-model="formData.RecurringType"
        @change="handleTypeChange"
        style="width: 100px; margin-right: 16px"
        :disabled="disabled"
      >
        <el-option label="每天" value="每天"></el-option>
        <el-option label="每周" value="每周"></el-option>
        <el-option label="每月" value="每月"></el-option>
      </el-select>

      <el-select
        v-model="formData.RecurringDay"
        v-if="formData.RecurringType !== '每天'"
        style="width: 100px; margin-right: 16px"
        :disabled="disabled"
      >
        <el-option
          v-for="item in dayList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>

      <el-time-select
        v-model="formData.RecurringTime"
        :picker-options="recurring"
        placeholder="选择时间"
        style="width: 100px"
        :disabled="disabled"
      >
      </el-time-select>
    </el-form-item>
    <el-form-item label="群发结束时间" prop="EndTime">
      <el-radio-group
        v-model="formData.RecurringEndType"
        class="endType"
        :disabled="disabled || StartEndTimeStep2Dbc"
      >
        <el-radio label="NeverEnd">永不</el-radio>
        <el-radio label="AfterCertainDate">
          <span style="margin-right: 18px">结束时间</span>
          <el-date-picker
            v-if="formData.RecurringEndType === 'AfterCertainDate'"
            v-model="formData.EndTime"
            type="datetime"
            style="width: 234px"
            placeholder="请选择群发结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="disabled"
          >
          </el-date-picker>
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="触达限制" label-width="84px" prop="cdxz">
      <el-radio-group v-model="formData.TouchLimitation" :disabled="disabled || formCurrency">
        <el-radio label="触达1次">触达1次</el-radio>
        <el-radio label="触达多次">触达多次</el-radio>
      </el-radio-group>
      <div
        style="margin-top: 14px"
        v-if="formData.TouchLimitation === '触达多次'"
      >
        客户
        <el-select
          v-model="formData.BehaviorFilterGroups[0].FilterItems[0].Operator"
          placeholder="没做过"
          style="width: 100px"
          :disabled="disabled || StartEndTimeStep2Dbc"
        >
          <el-option label="做过" value="做过"></el-option>
          <el-option label="没做过" value="没做过"></el-option>
        </el-select>
        <el-select
          v-model="formData.BehaviorFilterGroups[0].FilterItems[0].Value"
          placeholder="什么行为"
          style="margin-left: 16px"
          :disabled="disabled || StartEndTimeStep2Dbc"
        >
          <el-option
            v-for="item in customerBehaFields"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        则再次触达
      </div>
      <div
        style="margin-top: 14px"
        v-if="formData.TouchLimitation === '触达多次'"
      >
        同一客户每
        <el-input
          style="width: 100px"
          type="number"
          v-model="formData.TouchLimitationTimeNumber"
          placeholder=""
          :disabled="disabled || StartEndTimeStep2Dbc"
        ></el-input>
        <el-select
          v-model="formData.TouchLimitationTimeUnit"
          placeholder=""
          style="margin-left: 16px; width: 100px"
          :disabled="disabled || StartEndTimeStep2Dbc"
        >
          <el-option
            :label="item"
            :value="item"
            v-for="item in TouchLimitationTimeUnitOptions"
            :key="item"
          >
          </el-option>
        </el-select>
        触达1次，最多触达
        <el-input
          style="width: 100px"
          type="number"
          v-model="formData.TouchLimitationMaxNumber"
          placeholder=""
          :disabled="disabled || StartEndTimeStep2Dbc"
        ></el-input>
        次
      </div>
      <div v-if="TouchLimitationMoreHanderError" class="error-msg">
        必须填写
      </div>
    </el-form-item>
  </cond-card>
</template>

<script>
import { mapGetters } from "vuex";
import condCard from "@/components/cond-card";
import util from "@/util";
export default {
  components: {
    condCard,
  },
  props: {
    // group 模式出现的是【触发岂止日期】，对应的字段是 StartTime、EndTime
    // flow 模式出现的是【在前一流程完成后】，对应的字段是 TriggerTimeQuantity、TriggerTimeUnit
    model: {
      type: String,
      default: "group",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showZqxdsqf: {
      type: Boolean,
      default: true,
    },
    formCurrency:{
      type: Boolean,
      default: false,
    },
    StartEndTimeStep2Dbc: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      StartEndTime: [],
      TouchLimitationMoreHanderError: false,
      dayList: [],
      formData: {
        EndTime: "",
        RecurringType: "每天",
        RecurringDay: "1",
        RecurringTime: "10:00",
        RecurringEndType: "NeverEnd",
        // 触达限制
        TouchLimitation: "触达1次",
        TouchLimitationTimeNumber: "1",
        TouchLimitationTimeUnit: "",
        TouchLimitationMaxNumber: "1",
        BehaviorFilterGroups: [
          {
            FilterGroup: "人行为",
            ObjectAPIName: "CustomerBehavior",
            FilterItems: [
              {
                id: util.randomString(6),
                Operator: "",
                Value: "",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      customerBehavior: "common/customerBehavior",
      flowFields: "common/flowFields",
    }),
    customerBehaFields() {
      const behavior =
        this.customerBehavior.find((item) => item.APIName === "Behavior") || {};
      return behavior.PicklistOptions || [];
    },
    recurring() {
      const field =
        this.flowFields.find((item) => item.APIName === "RecurringTime") || {};
      const options = field.PicklistOptions || [];
      return {
        start: options[0],
        step: "00:01",
        end: options[options.length - 1],
      };
    },
    TouchLimitationTimeUnitOptions() {
      const behavior =
        this.flowFields.find(
          (item) => item.APIName === "TouchLimitationTimeUnit"
        ) || {};
      return behavior.PicklistOptions || [];
    },
  },
  watch: {
    filterTxt(newVal) {
      this.formData.BehaviorConditionLogic = newVal;
    },
  },
  methods: {
    handleTypeChange(val) {
      if (val === "每周") {
        this.dayList = [
          {
            label: "一",
            value: "1",
          },
          {
            label: "二",
            value: "2",
          },
          {
            label: "三",
            value: "3",
          },
          {
            label: "四",
            value: "4",
          },
          {
            label: "五",
            value: "5",
          },
          {
            label: "六",
            value: "6",
          },
          {
            label: "日",
            value: "7",
          },
        ];
      } else if (val === "每月") {
        const list = [];
        for (let i = 1; i <= 31; i++) {
          list.push({
            label: "" + i,
            value: "" + i,
          });
        }
        this.dayList = list;
      }
    },
    initForm(data = {}) {
      Object.keys(data).forEach((key) => {
        if (this.formData[key] !== undefined && data[key] !== undefined) {
          this.formData[key] = data[key];
        }
      });
      if (!this.formData.BehaviorFilterGroups.length) {
        this.formData.BehaviorFilterGroups = [
          {
            FilterGroup: "人行为",
            ObjectAPIName: "CustomerBehavior",
            FilterItems: [
              {
                id: util.randomString(6),
                Operator: "",
                Value: "",
              },
            ],
          },
        ]
      }
    },
    resetForm() {
      this.StartEndTime = [];
      this.formData = {
        EndTime: "",
        TouchLimitationTimeUnit: "",
        TouchLimitationTimeNumber: "1",
        RecurringType: "每天",
        RecurringDay: "1",
        RecurringTime: "10:00",
        RecurringEndType: "NeverEnd",
        // 触达限制
        TouchLimitation: "触达1次",
        TouchLimitationMaxNumber: "1",
        BehaviorFilterGroups: [
          {
            FilterGroup: "人行为",
            ObjectAPIName: "CustomerBehavior",
            FilterItems: [
              {
                id: util.randomString(6),
                Operator: "",
                Value: "",
              },
            ],
          },
        ],
      };
    },
    getResult() {
      if (!this.validate()) {
        return;
      }
      const formData = JSON.parse(JSON.stringify(this.formData));
      const param = {
        ...formData,
      };

      if (param.TouchLimitation !== "触达多次") {
        param.BehaviorFilterGroups = [];
        // param.BehaviorFilterGroups[0].FilterItems[0].Operator = "";
        // param.BehaviorFilterGroups[0].FilterItems[0].Value = "";
        delete param.TouchLimitationTimeNumber;
        delete param.TouchLimitationTimeUnit;
        delete param.TouchLimitationMaxNumber;
      }
      return param;
    },
    validate() {
      this.TouchLimitationMoreHanderError = false;
      let valid = true; // 是否验证通过，默认通过
      if (this.model === "group") {
        if (!this.StartEndTime.length) {
          valid = false;
        }
      }
      if (this.formData.TouchLimitation === "触达多次") {
        try {
          if (
            !this.formData.BehaviorFilterGroups[0].FilterItems[0].Operator ||
            !this.formData.BehaviorFilterGroups[0].FilterItems[0].Value ||
            !this.formData.TouchLimitationTimeNumber ||
            !this.formData.TouchLimitationTimeUnit ||
            !this.formData.TouchLimitationMaxNumber
          ) {
            valid = false;
            this.TouchLimitationMoreHanderError = true;
          }
        } catch (error) {
          valid = false;
          this.TouchLimitationMoreHanderError = true;
        }
      }

      return valid;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.endType {
  display: flex;
  flex-direction: column;
  /deep/ .el-radio {
    line-height: 36px;
  }
}
.cond-combination {
  .content {
    padding: 20px;
    padding-bottom: 6px;
    background: #f6f7f8;
    border-radius: 6px;
  }
}
.delay-wrap {
  margin-top: 20px;
  > span {
    margin-right: 10px;
    font-size: 12px;
  }
}

.error-msg {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>
