<!-- 单次定时群发 -->
<template>
  <cond-card title="转化目标设置">
    <div
      v-for="(path, indexP) in StatusConfigItems"
      :key="path._id"
      class="path-item"
    >
      <div class="index-p">{{ indexP + 1 }}</div>
      <i
        class="el-icon-close"
        @click="handleDeletePath(path)"
        v-if="indexP !== 0 && !disabled"
      />
      <el-form-item label="转化目标" label-width="94px">
        <el-input
          v-model="path.CampaignMemberStatus"
          placeholder="请输入路径名称"
          style="width: 440px"
          maxlength="100"
          show-word-limit
          :disabled="disabled || formJyMuster.CampaignMemberStatusStep4"
        ></el-input>
      </el-form-item>
      <el-form-item label="包含客户行为" label-width="94px" v-show="indexP > 0">
        <cond-item-wrap
          class="customerTouch"
          placeholder="客户被触达后"
        >
        <!-- <el-input
          style="width: 100px; margin-right: 14px"
          type="number"
          v-model="path.CustomerBehaviorExecuteTimeQuantity"
          placeholder=""
          :disabled="disabled || formJyMuster.CustomerBehaviorExecuteTimeStep4"
        ></el-input> -->
        <el-input
          style="width: 100px; margin-right: 14px"
          type="number"
          v-model="path.CustomerBehaviorExecuteTimeQuantity"
          :disabled="disabled"
          placeholder=""
        ></el-input>
        <!-- <el-select
          style="width: 100px; margin-right: 14px"
          v-model="path.CustomerBehaviorExecuteTimeUnit"
          :disabled="disabled || formJyMuster.CustomerBehaviorExecuteTimeStep4"
        > -->
        <el-select
          style="width: 100px; margin-right: 14px"
          :disabled="disabled"
          v-model="path.CustomerBehaviorExecuteTimeUnit"
        >
          <el-option label="天" value="天"> </el-option>
          <el-option label="周" value="周"> </el-option>
          <el-option label="月" value="月"> </el-option>
        </el-select>
        <span>内完成以下动作</span>
        </cond-item-wrap>
        <cond-item-wrap
          class="customerBehavior"
          placeholder="所有客户行为"
          :isEmpty="path.CustomerBehaviors.length === 0"
        >
          <path-item
            v-for="item in path.CustomerBehaviors"
            type="customer"
            :key="item._id"
            :detail="item"
            :fields="customerBehaFields"
            :disabled="disabled || formJyMuster.CustomerBehaviorsStep4"
            @remove="handleRemoveCondCustBeha(path, $event)"
          ></path-item>
          <div>
            <el-button
              :disabled="disabled || formJyMuster.CustomerBehaviorsStep4"
              type="text"
              @click="handleAddCondCustBeha(path)"
              >+ 添加条件</el-button
            >
          </div>
        </cond-item-wrap>
      </el-form-item>
      <el-form-item label="包含系统事件" label-width="94px" v-show="indexP > 0">
        <cond-item-wrap
          placeholder="所有系统事件"
          :isEmpty="path.SystemEvents.length === 0"
        >
          <path-item
            v-for="item in path.SystemEvents"
            type="system"
            :key="item._id"
            :detail="item"
            :disabled="disabled || formJyMuster.SystemEventsStep4"
            :fields="systemEventFields"
            @remove="handleRemoveSystemEvent(path, $event)"
          ></path-item>
          <div>
            <el-button
              :disabled="disabled || formJyMuster.SystemEventsStep4"
              type="text"
              @click="handleAddSystemEvent(path)"
              >+ 添加条件</el-button
            >
          </div>
        </cond-item-wrap>
      </el-form-item>
      <div class="split-line" v-if="indexP < StatusConfigItems.length - 1">
        <div></div>
        <i class="el-icon-arrow-down" />
        <div></div>
      </div>
    </div>
    <div class="split-line">
      <div></div>
      <el-button
        :disabled="disabled || formJyMuster.CampaignMemberStatusStep4"
        type="primary"
        class="btn-new"
        round
        @click="handleAddPath"
        >+ 新的目标</el-button
      >
      <div></div>
    </div>
  </cond-card>
</template>

<script>
import { mapGetters } from "vuex";
import util from "@/util";
import condCard from "@/components/cond-card";
import condItemWrap from "@/components/cond-item-wrap";
import pathItem from "./path-item";
export default {
  components: {
    condCard,
    condItemWrap,
    pathItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formJyMuster: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      StatusConfigItems: [
        {
          _id: "111",
          CampaignMemberStatus: "",
          CustomerBehaviorExecuteTimeQuantity: 15,
          CustomerBehaviorExecuteTimeUnit: '天',
          Seq: "1",
          CustomerBehaviors: [],
          SystemEvents: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerBehavior: "common/customerBehavior",
      campaignStatusConfig: "common/campaignStatusConfig",
      campaignStatusMapping: "common/campaignStatusMapping",
    }),
    customerBehaFields() {
      const behavior =
        this.customerBehavior.find((item) => item.APIName === "Behavior") || {};
      return behavior.PicklistOptions || [];
    },
    systemEventFields() {
      const statusValue =
        this.campaignStatusMapping.find(
          (item) => item.APIName === "StatusValue"
        ) || {};
      return statusValue.PicklistOptions || [];
    },
    defaultValue() {
      const defVal =
        this.campaignStatusConfig.find(
          (item) => item.APIName === "CampaignMemberStatus"
        ) || {};
      return defVal.DefaultValue || "";
    },
  },
  methods: {
    initForm(data = []) {
      data.forEach((path) => {
        path.CustomerBehaviors = path.CustomerBehaviors.map((item) => {
          return {
            _id: util.randomString(6),
            Value: item,
          };
        });
        path.SystemEvents = path.SystemEvents.map((item) => {
          return {
            _id: util.randomString(6),
            Value: item,
          };
        });
      });
      this.StatusConfigItems = data;
    },
    resetForm() {
      this.setDefault();
    },
    setDefault() {
      this.StatusConfigItems = [
        {
          _id: "111",
          CampaignMemberStatus: this.defaultValue,
          Seq: "1",
          CustomerBehaviors: [],
          SystemEvents: [],
        },
      ];
    },
    getResult() {
      const result = [];
      const eventMap = {};
      let passed = true;
      this.StatusConfigItems.forEach((path, index) => {
        if (index > 0) {
          if (
            path.CustomerBehaviors.length === 0 &&
            path.SystemEvents.length === 0
          ) {
            passed = false;
            setTimeout(() => {
              this.$message.error(`路径${index + 1}，请至少选择一个事件！`);
            }, 0);
          }
        }

        const item = {
          ...path,
          CustomerBehaviors: path.CustomerBehaviors.map((item) => {
            if (eventMap[item.Value] === undefined) {
              eventMap[item.Value] = 1;
            } else {
              eventMap[item.Value]++;
            }
            return item.Value;
          }),
          SystemEvents: path.SystemEvents.map((item) => {
            if (eventMap[item.Value] === undefined) {
              eventMap[item.Value] = 1;
            } else {
              eventMap[item.Value]++;
            }
            return item.Value;
          }),
        };
        result.push(item);
      });

      // 找出重复的事件
      const repeats = Object.keys(eventMap).filter((key) => eventMap[key] > 1);
      if (repeats.length > 0) {
        passed = false;
        setTimeout(() => {
          this.$message.error(`不能选择重复的事件：${repeats.join("，")}`);
        }, 0);
      }
      if (passed === false) {
        return false;
      }
      return result;
    },
    handleAddCondCustBeha(path) {
      path.CustomerBehaviors.push({
        _id: util.randomString(6),
        Value: "",
      });
    },
    handleRemoveCondCustBeha(path, detail) {
      const index = path.CustomerBehaviors.indexOf(detail);
      path.CustomerBehaviors.splice(index, 1);
    },
    handleAddSystemEvent(path) {
      path.SystemEvents.push({
        _id: util.randomString(6),
        Value: "",
      });
    },
    handleRemoveSystemEvent(path, detail) {
      const index = path.SystemEvents.indexOf(detail);
      path.SystemEvents.splice(index, 1);
    },
    handleAddPath() {
      this.StatusConfigItems.push({
        _id: util.randomString(6),
        CampaignMemberStatus: "",
        CustomerBehaviorExecuteTimeQuantity: 15,
        CustomerBehaviorExecuteTimeUnit: '天',
        Seq: +this.StatusConfigItems[this.StatusConfigItems.length - 1].Seq + 1,
        CustomerBehaviors: [],
        SystemEvents: [],
      });
    },
    handleDeletePath(detail) {
      const index = this.StatusConfigItems.indexOf(detail);
      this.StatusConfigItems.splice(index, 1);
    },
  },
  mounted() {
    this.setDefault();
  },
};
</script>

<style lang="less" scoped>
.path-item {
  position: relative;
  > .index-p {
    position: absolute;
    left: 0;
    top: 5px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    background: #f6f7f8;
    border-radius: 50%;
    text-align: center;
  }
  > i.el-icon-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #9a9a9e;
    cursor: pointer;
    z-index: 1;
  }
}
.split-line {
  display: flex;
  align-items: center;
  height: 48px;
  > i {
    flex: 0 0 auto;
    margin: 0 22px;
    font-size: 24px;
    font-weight: bold;
    color: #b7b6b9;
  }
  > div {
    flex: 1 1 auto;
    height: 1px;
    background: #b7b6b9;
  }
  .btn-new {
    margin: 0 14px;
    padding: 10px 23px;
    border: 2px solid #ffffff;
    background: #1895fb;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  }
}
/deep/ .cond-item-wrap {
  margin-bottom: 0px;
  background: #f6f7f8;
}
/deep/ .customerTouch .items{
  border-bottom: none;
  border-radius: 2px;
}
/deep/ .customerBehavior .items{
  border-top: none;
  border-radius: 2px;
}
</style>
