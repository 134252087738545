import { render, staticRenderFns } from "./save-template.vue?vue&type=template&id=235e10a4&scoped=true&"
import script from "./save-template.vue?vue&type=script&lang=js&"
export * from "./save-template.vue?vue&type=script&lang=js&"
import style0 from "./save-template.vue?vue&type=style&index=0&id=235e10a4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235e10a4",
  null
  
)

export default component.exports