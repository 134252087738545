<!-- 执行触达时间 -->
<template>
  <cond-card title="执行触达时间">
    <div class="delay-wrap">
      <span>在满足触发条件后</span>
      <el-select
        v-model="formData.ExecuteTimeType"
        style="width: 125px; margin-right: 14px"
        :disabled="disabled || formJyMuster.ExecuteTimeTypeStep2Dctj"
      >
        <el-option label="延迟" value="延迟"></el-option>
        <el-option label="立即" value="立即"></el-option>
      </el-select>
      <el-select
        v-model="formData.ExecuteTimeQuantity"
        v-show="formData.ExecuteTimeType === '延迟'"
        style="width: 125px; margin-right: 14px"
        :disabled="disabled || formJyMuster.ExecuteTimeTypeStep2Dctj"
      >
        <el-option
          v-for="(item, index) in quantitys"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="formData.ExecuteTimeUnit"
        v-show="formData.ExecuteTimeType === '延迟'"
        style="width: 125px; margin-right: 14px"
        :disabled="disabled || formJyMuster.ExecuteTimeTypeStep2Dctj"
      >
        <el-option label="分" value="分"> </el-option>
        <el-option label="小时" value="小时"> </el-option>
        <el-option label="天" value="天"> </el-option>
      </el-select>
      <span>执行</span>
    </div>
  </cond-card>
</template>

<script>
import condCard from "@/components/cond-card";
export default {
  components: {
    condCard,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formJyMuster: {
      type: Object,
      default: () => {
        return { ExecuteTimeTypeStep2Dctj: false}
      },
    },
  },
  data() {
    return {
      quantitys: [],
      formData: {
        ExecuteTimeType: "延迟",
        ExecuteTimeUnit: "分",
        ExecuteTimeQuantity: 60,
      },
    };
  },
  methods: {
    initForm(data = {}) {
      Object.keys(data).forEach((key) => {
        if (this.formData[key] !== undefined && data[key] !== undefined) {
          this.formData[key] = data[key];
        }
      });
    },
    resetForm() {
      this.formData = {
        ExecuteTimeType: "延迟",
        ExecuteTimeUnit: "分",
        ExecuteTimeQuantity: 60,
      };
    },
    getResult() {
      return {
        ...this.formData,
      };
    },
  },
  mounted() {
    const quantitys = [];
    for (let i = 1; i <= 60; i++) {
      quantitys.push({
        label: i,
        value: i,
      });
    }
    this.quantitys = quantitys;
  },
};
</script>

<style lang="less" scoped>
.delay-wrap {
  margin-top: 20px;
  > span {
    margin-right: 10px;
  }
}
</style>
