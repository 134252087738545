<template>
  <div class="cond-item-customer">
    <el-row :gutter="20">
      <!-- 序号 -->
      <el-col :span="1" class="index-wrap">
        <div class="index">{{ index + 1 }}</div>
      </el-col>
      <!-- 第一列：Operator -->
      <el-col :span="4">
        <el-select
          v-model="detail.Operator"
          style="width: 100%"
          :disabled="disabled"
        >
          <el-option label="做过" value="做过"></el-option>
          <el-option label="没做过" value="没做过"></el-option>
        </el-select>
        <div class="error-msg">{{ operatorMsg }}</div>
      </el-col>
      <!-- 第二列：指标 -->
      <el-col :span="8">
        <el-select
          v-model="detail.Value"
          style="width: 100%"
          :disabled="disabled"
          filterable
          placeholder="请选择或搜索"
        >
          <el-option
            v-for="item in fields"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
        <div class="error-msg">{{ valueMsg }}</div>
      </el-col>
      <el-col :span="2" style="line-height: 36px">
        <i v-if="!disabled" class="el-icon-close" @click="handleRemove"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueMsg: "",
      operatorMsg: "",
    };
  },
  methods: {
    handleRemove() {
      this.$emit("remove", this.detail);
    },
    validate() {
      const { Operator, Value } = this.detail;
      let valid = true; // 是否校验通过

      if (!Operator) {
        this.operatorMsg = "请选择 operator";
        valid = false;
      } else {
        this.operatorMsg = "";
      }

      if (Array.isArray(Value)) {
        // value 是一个数组
        if (Value.length === 0) {
          // 数组为空，不通过
          this.valueMsg = "请选择 value";
          valid = false;
        } else if (Value.find((v) => !v) !== undefined) {
          // 数组中有空值，不通过
          this.valueMsg = "请选择 value";
          valid = false;
        } else {
          this.valueMsg = "";
        }
      } else if (!Value) {
        this.valueMsg = "请选择 value";
        valid = false;
      } else {
        this.valueMsg = "";
      }

      return valid;
    },
  },
};
</script>

<style lang="less" scoped>
.cond-item-customer {
  margin-bottom: 14px;
  .index-wrap {
  }
  .index {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-top: 7px;
    font-size: 12px;
    background: #f6f7f8;
    border-radius: 50%;
    text-align: center;
  }
  .el-icon-close {
    font-weight: 500;
    color: #9a9a9e;
    cursor: pointer;
  }
  /deep/ .el-date-editor {
    .el-range-separator {
      padding: 0;
    }
  }

  .error-msg {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}
</style>
