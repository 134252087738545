<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :modal="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            editStatus === "show"
              ? "查看流程"
              : saveType === "add"
              ? "新建流程"
              : "修改流程"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <!-- <el-button
            @click="showPre = true"
            round
            style="background: #4d4b58; color: #fff"
            >预览分群概貌</el-button
          > -->
          <el-button
            :disabled="disabled"
            type="primary"
            round
            @click="handleSave"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
        >
          <cond-card>
            <el-form-item label="流程名称" prop="Name" label-width="80px">
              <el-input
                v-model="formData.Name"
                placeholder="输入流程名称"
                style="width: 440px"
                :disabled="disabled || formDisabled1"
              ></el-input>
            </el-form-item>
          </cond-card>
          <customer-filter
            ref="customerFilter"
            title="客户分流筛选条件"
            :disabled="formCurrency ? formCurrency : disabled"
            :max-height="230"
            @conditions-change="conditions => this.flows = conditions"
          ></customer-filter>
          <cond-card title="触发类型">
            <div class="plan-types">
              <radio-item
                v-for="(item, index) in planTypes"
                :key="index"
                :detail="item"
                :active="formData.TriggerType === item.name"
                @change="handleTypeChange"
                :disabled="formCurrency ? formCurrency : disabled"
              ></radio-item>
            </div>
          </cond-card>
          <cond-zqxdsqf
            v-show="formData.TriggerType === '周期性定时群发'"
            ref="zqxdsqf"
            model="flow"
            :disabled="disabled"
            :StartEndTimeStep2Dbc="formJyMuster.StartEndTimeStep2Dbc"
            :formCurrency="formCurrency"
            :showZqxdsqf="false"
          ></cond-zqxdsqf>
          <cond-dctj
            v-show="formData.TriggerType === '单次触发'"
            ref="dctj"
            model="flow"
            :disabled="disabled || formJyMuster.StartEndTimeStep2Dbc"
            :StartEndTimeStep2Dbc="formJyMuster.StartEndTimeStep2Dbc"
            :showCxdz="false"
          ></cond-dctj>
          <cond-cdsj
            ref="cdsj"
            :disabled="
              formJyMuster.StartEndTimeStep2Dbc
                ? formJyMuster.StartEndTimeStep2Dbc
                : disabled
            "
          ></cond-cdsj>
          <cond-card title="触达渠道">
            <el-form-item
              label="触达渠道"
              prop="MessageChannel"
              label-width="70px"
            >
              <el-cascader
                v-model="formData.MessageChannel"
                :options="MessageChannelOptions"
                @change="handleChannelChange"
                style="width: 320px"
                :disabled="formCurrency ? formCurrency : disabled"
              ></el-cascader>
            </el-form-item>
          </cond-card>
          <send-people-select
            v-if="(formData.MessageChannel.indexOf('企业微信') !== -1)"
            :MessageChannel="formData.MessageChannel"
            :SenderChooseType.sync="formData.SenderChooseType"
            :SenderChooseList.sync="formData.SenderChooseList"
            :CustomerGroup="CustomerGroup"
            :disabled="formCurrency ? formCurrency : disabled"
            :flows="flows"
          ></send-people-select>
          <cond-card title="触达内容">
            <el-form-item
              label="触达内容"
              prop="MessageTemplate"
              label-width="70px"
            >
              <el-select
                v-model="formData.MessageTemplate"
                placeholder="请选择触达内容"
                style="width: 320px"
                filterable
                :disabled="disabled || formDisabled1"
              >
                <el-option
                  v-for="item in MessageTemplateOptions"
                  :key="item._id"
                  :label="item.Name"
                  :value="item._id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiGetTemplateListByChannel } from "@/api/template";
import customerFilter from "@/components/customer-filter";
import util from "@/util";
import radioItem from "@/components/radio-item";
import condCard from "@/components/cond-card";
import condDctj from "./con-dctj";
import condZqxdsqf from "./con-zqxdsqf";
import condCdsj from "./con-cdsj";
import sendPeopleSelect from "./send-people-select";
import { stringify } from "querystring";
export default {
  components: {
    radioItem,
    condCard,
    condDctj,
    condZqxdsqf,
    condCdsj,
    customerFilter,
    sendPeopleSelect
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formJyMuster: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editStatus: {
      type: String,
      default: () => "edit",
    },
    CustomerGroup: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      direction: "rtl",
      quantitys: [],
      formCurrency: false,
      formDisabled0: false,
      formDisabled1: false,
      formData: {
        _id: "",
        Name: "",
        TriggerType: "单次定时群发",
        TriggerTimeUnit: "小时",
        TriggerTimeQuantity: "1",
        StartEndTime: [],
        MessageChannel: [],
        MessageTemplate: "",
        SenderChooseType: "",
        SenderChooseList: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入流程名称",
          trigger: "blur",
        },
        MessageChannel: {
          required: true,
          message: "请选择触达渠道",
          trigger: "change",
        },
        MessageTemplate: {
          required: true,
          message: "请选择触达内容",
          trigger: "change",
        },
      },
      planTypes: [
        {
          icon: "iconfont icon-clock",
          name: "单次定时群发",
          desc: "创建一次性执行的计划",
        },
        {
          icon: "iconfont icon-rili",
          name: "周期性定时群发",
          desc: "创建重复执行的计划",
        },
        {
          icon: "iconfont icon-lightning",
          name: "单次触发",
          desc: "创建一次性的触发型计划",
        },
      ],
      flows: {},
      MessageTemplateOptions: [],
      firstFlag: true
    };
  },
  computed: {
    ...mapGetters({
      allChannels: "common/allChannels",
      typesChannels: "common/typesChannels",
    }),
    MessageChannelOptions() {
      const channels = this.allChannels || [];
      const map = {};
      const result = [];
      (this.typesChannels || []).forEach((typeName) => {
        map[typeName] = {};
      });
      channels.forEach((item) => {
        if (item.IsActive == 0) {
          return;
        }
        if (
          map[item.Type] === undefined ||
          Object.keys(map[item.Type]).length === 0
        ) {
          map[item.Type] = {
            label: item.Type,
            value: item.Type,
            children: [],
          };
        }
        map[item.Type].children.push({
          label: item.Name,
          value: item._id,
        });
      });
      Object.keys(map).forEach((key) => {
        result.push(map[key]);
      });
      return result;
    },
  },
  watch: {
    exist(newVal) {
      if (newVal === false) {
        this.resetForm();
      }
    },
    detail(newvalue, oldvalue) {
      if (this.formJyMuster.FlowsStep2Dbc) {
        this.formCurrency = true;
        if (newvalue.FlowLevel === 0) {
          this.formDisabled0 = true;
        } else {
          this.formDisabled1 = true;
        }
      }
    },
    "formData.MessageChannel": {
      handler(val) {
        if (this.firstFlag) {
          this.firstFlag = !this.firstFlag
          return
        }
        if (val && val[0] !== '企业微信') {
          this.formData.SenderChooseType = ''
          this.formData.SenderChooseList = ''
          return
        }
        this.formData.SenderChooseType = '全部关联人员发送'
        this.formData.SenderChooseList = ''
      }
    },
  },
  methods: {
    handleTypeChange(plan) {
      this.formData.TriggerType = plan.name;
    },
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.customerFilter.validate();
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      if (this.$el.querySelectorAll(".err-msg").length > 0) {
        return;
      }
      const customerResult = this.$refs.customerFilter.getResult();
      if (customerResult === false) {
        return false;
      }
      if (this.next2Disabled()) {
        return;
      }
      const { _id, Name, TriggerType, MessageChannel, MessageTemplate, SenderChooseType, SenderChooseList } =
        this.formData;

      const param = {
        _id: this.saveType === "add" ? util.randomString(6) : _id,
        FlowLevel: this.detail.FlowLevel,
        Parents: this.detail.Parents,
        Children: [],
        Name,
        TriggerType,
        MessageChannelType: MessageChannel[0],
        MessageChannel: MessageChannel[1],
        MessageTemplate: MessageTemplate,
        ...customerResult,
        ...this.$refs.cdsj.getResult(),
        SenderChooseType,
        SenderChooseList
      };

      param.CustomerFilterGroups = param.FilterGroups;
      delete param.FilterGroups;

      if (this.formData.MessageChannel.indexOf('企业微信') !== -1) {
        if (!this.formData.SenderChooseType) {
          this.$message.error("请设置发送人员选择类型");
          return
        }
        if (this.formData.SenderChooseType === "指定人员发送" && !this.formData.SenderChooseList) {
          this.$message.error("请指定发送人员");
          return
        }
      }

      if (TriggerType === "单次触发") {
        const item = this.$refs.dctj.getResult();
        Object.assign(param, item);
      }

      if (TriggerType === "周期性定时群发") {
        if (!this.$refs.zqxdsqf.validate()) {
          return;
        }
        const item = this.$refs.zqxdsqf.getResult();

        Object.assign(param, item);
      }

      this.$emit("success", param);
    },
    resetForm() {
      this.firstFlag = false
      this.formData = {
        _id: "",
        Name: "",
        TriggerType: "单次定时群发",
        StartEndTime: [],
        MessageChannel: [],
        MessageTemplate: "",
        SenderChooseType: '',
        SenderChooseList: ''
      };
      this.formCurrency = false;
      this.formDisabled0 = false;
      this.formDisabled1 = false;
      this.$refs.customerFilter.resetForm();
      this.$refs.dctj.resetForm();
      this.$refs.cdsj.resetForm();
      this.$refs.zqxdsqf.resetForm();
      setTimeout(() => {
        this.$refs.form.clearValidate();
      }, 0);
    },
    handleChannelChange(channel) {
      this.formData.MessageTemplate = "";
      this.getTemplates(channel[1]);
    },
    getTemplates(channelId) {
      apiGetTemplateListByChannel(channelId).then((res) => {
        this.MessageTemplateOptions = res.templates || [];
      });
    },
    initForm(data) {
      this.firstFlag = true
      this.formData = {
        _id: data._id,
        Name: data.Name,
        TriggerType: data.TriggerType,
        TriggerTimeUnit: data.TriggerTimeUnit,
        TriggerTimeQuantity: data.TriggerTimeQuantity,
        StartEndTime: data.StartEndTime,
        MessageChannel: [data.MessageChannelType, data.MessageChannel],
        MessageTemplate: data.MessageTemplate,
        SenderChooseType: data.SenderChooseType,
        SenderChooseList: data.SenderChooseList,
      };
      const customerData = {
        FilterConditionLogic: data.FilterConditionLogic,
        FilterConditionType: data.FilterConditionType,
      };
      if (Array.isArray(data.CustomerFilterGroups)) {
        data.CustomerFilterGroups.forEach((item) => {
          if (item.FilterGroup === "客户") {
            customerData.condCustList = item.FilterItems;
          } else if (item.FilterGroup === "车辆") {
            customerData.condCarList = item.FilterItems;
          } else if (item.FilterGroup === "连接渠道") {
            customerData.condCliquePointsAndRechargesList = item.FilterItems;
          } else if (item.FilterGroup === "集团会员卡券") {
            customerData.condCliqueCardsAndVouchersList = item.FilterItems;
          } else if (item.FilterGroup === "企业积分") {
            customerData.condDealerPointsAndRechargesList = item.FilterItems;
          } else if (item.FilterGroup === "企业卡券") {
            customerData.condDealerCardsAndVouchersList = item.FilterItems;
          } else if (item.FilterGroup === "人行为") {
            customerData.condCustBehaList = item.FilterItems;
          } else if (item.FilterGroup === "车行为") {
            customerData.condCarBehaList = item.FilterItems;
          } else if (item.FilterGroup === "关系") {
            customerData.condRelaList = item.FilterItems;
          } else if (item.FilterGroup === "模型") {
            customerData.modelFilterList = item.FilterItems || [];
            customerData.modelFilterList.forEach((child) => {
              child.nowobject = item.ObjectAPIName;
            });
          } else if (item.FilterGroup === "ID上传") {
            customerData.idFilterList = item.FilterItems || [];
          }
        });
      }
      this.$nextTick(() => {
        this.$refs.customerFilter.initForm(customerData);
        this.$refs.cdsj.initForm(data);
        this.$refs.dctj.initForm(data);
        this.$refs.zqxdsqf.initForm(data);
        this.getTemplates(data.MessageChannel);
      });
    },
    next2Disabled() {
      let disabled = false;
      if (this.formData.TriggerType === "单次触发") {
        if (this.$refs.dctj) {
          disabled = !this.$refs.dctj.validate();
        }
      }
      return disabled;
    },
  },
  mounted() {
    const quantitys = [];
    for (let i = 1; i <= 30; i++) {
      quantitys.push({
        label: i,
        value: i,
      });
    }
    this.quantitys = quantitys;
  },
};
</script>

<style lang="less">
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  /deep/ .el-form-item__error {
    padding-left: 70px;
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
  .delay-wrap {
    margin-top: 20px;
    > span {
      margin-right: 10px;
    }
  }
}
</style>
