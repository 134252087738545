<!-- 单次定时群发 -->
<template>
  <div>
    <!-- <el-form-item label="对照组">
      <el-switch v-model="duizhao"> </el-switch>
      <span style="margin: 0 16px">对照组比例</span>
      <el-input placeholder="请输入比例" v-model="persent" style="width: 140px">
        <template slot="append">%</template>
      </el-input>
    </el-form-item> -->
    <!-- <el-form-item label="全局触达限制">
      <el-switch v-model="quanju"> </el-switch>
    </el-form-item> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      duizhao: true,
      quanju: true,
      persent: 10,
    };
  },
  methods: {
    initForm(data = {}) {
      Object.keys(data).forEach((key) => {
        if (this.formData[key] !== undefined && data[key] !== undefined) {
          this.formData[key] = data[key];
        }
      });
    },
    resetForm() {
      this.formData = {
        StartTime: "",
      };
    },
    getResult() {
      return {
        ...this.formData,
      };
    },
  },
};
</script>

<style lang="less" scoped></style>
