<!-- 多波次流程画布 -->
<template>
  <div>
    <cond-card title="多波次流程画布">
      <el-form-item label="流程起止日期" prop="StartEndTime">
        <!-- <el-date-picker
          v-model="StartTime"
          type="datetime"
          style="width: 320px"
          placeholder="请选择单次群发时间"
          value-format="yyyy-MM-dd HH:mm:ss"
          :disabled="disabled"
        >
        </el-date-picker> -->
        <template v-if="!disabled && formJyMuster.StartEndTimeStep2Dbc">
          {{ StartEndTime[0] }} 至
          <el-date-picker
            v-model="StartEndTime[1]"
            type="datetime"
            placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7;
              },
            }"
          >
          </el-date-picker>
        </template>
        <el-date-picker
          v-else
          v-model="StartEndTime"
          type="datetimerange"
          style="width: 400px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :disabled="disabled || formJyMuster.StartEndTimeStep2Dbc"
        >
        </el-date-picker>
      </el-form-item>
      <cond-cdxz v-if="showCxdz"></cond-cdxz>
      <el-button
        type="primary"
        class="btn-new"
        round
        @click="handleNewProcess(null)"
        :disabled="disabled"
        >+ 新的流程</el-button
      >
      <process-drawer
        ref="processDrawer"
        :exist.sync="existDrawer"
        :saveType="editType"
        :editStatus="editStatus"
        :detail="curProcess"
        @success="handleSaved"
        :disabled="disabled || disabledFlag"
        :CustomerGroup="CustomerGroup"
        :formJyMuster="formJyMuster"
      ></process-drawer>
    </cond-card>
    <div class="canvas" ref="canvas">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <path
          v-for="item in pathList"
          :key="item.path"
          :d="item.d"
          stroke="#9BD1FD"
          stroke-width="4"
          fill="none"
        />
      </svg>
      <div class="flow-row" v-for="(row, index) in rowList" :key="index">
        <div
          v-for="item in row"
          :key="item._id"
          :ref="item._id"
          class="flow-item"
        >
          <div class="header">
            <span>{{ item.Name }}</span>
            <i
              v-if="!(disabled || formJyMuster.handleStep2Dbc)"
              class="el-icon-error"
              @click="handleRemove(item)"
            ></i>
          </div>
          <div class="arr-item border">
            <div class="title">触发类型</div>
            <div class="value">{{ item.TriggerType }}</div>
          </div>
          <div class="arr-item border">
            <div class="title">触达时间</div>
            <div class="value" v-if="item.ExecuteTimeType === '立即'">
              {{ item.ExecuteTimeType }}
              执行
            </div>
            <div class="value" v-else>
              {{ item.ExecuteTimeType }}
              {{ item.ExecuteTimeQuantity }}
              {{ item.ExecuteTimeUnit }}
              执行
            </div>
          </div>
          <div class="arr-item">
            <div class="title">触达渠道</div>
            <div class="value">
              {{ channelLabelMap[item.MessageChannel] || item.MessageChannel }}
            </div>
          </div>
          <div class="footer-wrap">
            <div class="btn-list">
              <div
                v-if="!(disabled || formJyMuster.handleStep2Dbc)"
                class="btn-footer new"
                round
                @click="handleNewProcess(item)"
              >
                <i class="el-icon-plus" />
              </div>
              <div
                v-if="!(disabled || formJyMuster.handleStep2Dbc)"
                class="btn-footer link"
                round
                @click="handleLink(item)"
              >
                <i class="iconfont icon-link" />
              </div>
              <div
                class="btn-footer edit"
                round
                @click="handleEditProcess(item)"
              >
                <i class="el-icon-edit"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="关联现有的流程"
      :visible.sync="linkVisible"
      :modal="false"
      width="400px"
    >
      <el-form-item label="选择现有的流程">
        <el-select v-model="linkChildId" :disabled="disabled" multiple>
          <el-option
            v-for="item in linkList"
            :key="item._id"
            :label="item.Name"
            :value="item._id"
          ></el-option>
        </el-select>
      </el-form-item>
      <span slot="footer" class="dialog-footer">
        <el-button @click="linkVisible = false" type="text">取 消</el-button>
        <el-button type="primary" @click="linkConfirm" round>关 联</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import condCard from "@/components/cond-card";
import processDrawer from "./process-drawer";
import condCdxz from "./con-cdxz";
import moment from "moment";

export default {
  components: {
    condCard,
    processDrawer,
    condCdxz,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCxdz: {
      type: Boolean,
      default: true,
    },
    formJyMuster: {
      type: Object,
      default: () => {
        return {};
      },
    },
    editStatus: {
      type: String,
      default: () => 'edit'
    },
    CustomerGroup: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: "",
      existDrawer: false,
      linkVisible: false,
      editType: "add",
      curProcess: {
        FlowLevel: 0,
        Parents: [],
      },
      StartEndTime: [],
      Flows: [],
      linkList: [],
      linkParent: "",
      linkChildId: [],
      pathList: [],
      disabledFlag: false
    };
  },
  watch: {
    isShow(newVal) {
      if (newVal === true) {
        this.debouncePathList();
      }
    },
  },
  computed: {
    ...mapGetters({
      allChannels: "common/allChannels",
    }),
    channelLabelMap() {
      const map = {};
      this.allChannels.forEach((item) => {
        map[item._id] = item.Name;
      });
      return map;
    },
    rowList() {
      const result = [];
      this.Flows.forEach((item) => {
        const lever = +item.FlowLevel;
        if (result[lever] === undefined) {
          result[lever] = [];
        }
        result[lever].push(item);
      });
      console.log(result);
      return result;
    },
  },
  methods: {
    handleSaved(flow) {
      this.existDrawer = false;
      if (this.editType === "add") {
        flow.Parents.forEach((id) => {
          const parent = this.Flows.find((flow) => flow._id === id);
          if (parent) {
            parent.Children.push(flow._id);
          }
        });
        this.Flows.push(flow);
      } else {
        this.Flows.forEach((item) => {
          if (item._id === flow._id) {
            flow.Children = item.Children;
            Object.assign(item, flow);
          }
        });
      }

      this.debouncePathList();
    },
    handleNewProcess(parent) {
      this.editType = "add";
      this.existDrawer = true;
      if (parent) {
        this.curProcess = {
          FlowLevel: +parent.FlowLevel + 1,
          Parents: [parent._id],
        };
      } else {
        this.curProcess = {
          FlowLevel: 0,
          Parents: [],
        };
      }
    },
    handleEditProcess(detail) {
      this.curProcess = detail;
      this.editType = "edit";
      this.existDrawer = true;
      
      if (detail.Parents.length && this.formJyMuster.isPause) {
        this.disabledFlag = true
      }
      this.$nextTick(() => {
        this.$refs.processDrawer.initForm(detail);
      });
    },
    /**
     * 删除流程
     */
    handleRemove(flow) {
      // 首先处理父子关系
      this.removeRelation(flow);
      // 根据父子关系，删除节点
      this.removeFlow();
      this.debouncePathList();
    },
    /**
     * 移除掉节点之间的父子关系
     */
    removeRelation(flow) {
      // 要删除的节点，它的所有父节点的 Children 属性置空
      flow.Parents.forEach((p) => {
        const parent = this.Flows.find((item) => item._id === p);
        parent.Children = parent.Children.filter((item) => item !== flow._id);
      });
      // 标记该节点需要删除
      flow.removed = true;
      // 处理子节点，将当前节点从子节点的 Parents 中删掉

      flow.Children.forEach((childId) => {
        const Children = this.Flows.find((item) => item._id === childId);
        if (Children) {
          const index = Children.Parents.indexOf(flow._id);
          Children.Parents.splice(index, 1);
          // 此时，如果 Children.Parents 为空了，说明它与前面的链路已经断了，所以也是要删除的
          if (Children.Parents.length === 0) {
            this.removeRelation(Children);
          }
        }
      });
    },
    /**
     * 删除没有父节点的元素（层级为0的除外，它们本来就没有父节点）
     */
    removeFlow() {
      this.Flows = this.Flows.filter((item) => item.removed !== true);
    },
    handleLink(path) {
      this.linkVisible = true;
      // 只能link层级比自己低的节点
      this.linkParent = path;
      this.linkList = this.Flows.filter(
        (item) => +item.FlowLevel > +path.FlowLevel
      );
      this.linkChildId = path.Children || [];
    },
    linkConfirm() {
      this.linkVisible = false;
      this.linkParent.Children = this.linkChildId;
      this.Flows.forEach((item) => {
        if (this.linkChildId.includes(item._id)) {
          !item.Parents.includes(this.linkParent._id) &&
            item.Parents.push(this.linkParent._id);
        } else {
          let index = item.Parents.indexOf(this.linkParent._id);
          index > -1 && item.Parents.splice(index, 1);
        }
      });
      this.debouncePathList();
    },
    initForm(flows, StartTime, EndTiime) {
      // 标记父子关系
      let setTimeFlag = false;
      if (StartTime && EndTiime) {
        setTimeFlag = true;
        this.StartEndTime = [StartTime, EndTiime];
      }
      flows.forEach((item) => {
        if (!item.Parents) {
          item.Parents = [];
        }
        if (item.StartTime && item.EndTime) {
          setTimeFlag = true;
          this.StartEndTime = [item.StartTime, item.EndTime];
        } else if (!setTimeFlag) {
          this.StartEndTime = [];
        }
        if (!item.Children) {
          item.Children = [];
        }
        item.Parents.forEach((p) => {
          const parent = flows.find((fl) => fl._id === p);
          parent.Children.push(item._id);
        });
      });
      this.Flows = flows;
    },
    resetForm() {
      this.StartEndTime = [];
      this.Flows = [];
    },
    getResult() {
      this.Flows.forEach((item) => {
        // 编辑时，Parents保存的是节点的 id，保存时候要换成索引
        const parents = [];
        item.Parents.forEach((pid) => {
          const index = this.Flows.findIndex((f) => {
            return f._id === pid;
          });
          parents.push(index);
        });
        item.Parents = parents;
      });
      const flows = JSON.parse(JSON.stringify(this.Flows));
      console.log(flows);
      flows.forEach((item) => {
        item.Parents.forEach((pid, i) => {
          const findIndex = flows.findIndex((flow) => {
            return flow._id === pid;
          });

          if (findIndex !== -1) {
            item.Parents[i] = findIndex;
          }
        });
        item.Children.forEach((pid, i) => {
          const findIndex = flows.findIndex((flow) => {
            return flow._id === pid;
          });
          if (findIndex !== -1) {
            item.Children[i] = findIndex;
          }
        });
      });

      return flows.map((item) => {
        if (item.TriggerType === "周期性定时群发") {
          if (item.TouchLimitation !== "触达多次") {
            item.BehaviorFilterGroups = [];
            // param.BehaviorFilterGroups[0].FilterItems[0].Operator = "";
            // param.BehaviorFilterGroups[0].FilterItems[0].Value = "";
            delete item.TouchLimitationTimeNumber;
            delete item.TouchLimitationTimeUnit;
            delete item.TouchLimitationMaxNumber;
          }
        }
        return {
          ...item,
          StartTime: this.StartEndTime[0],
          EndTime: this.StartEndTime[1],
        };
      });
    },
    /**
     * 计算节点之间的连线
     */
    getPathList() {
      // 该模块不显示的时候不用计算
      if (this.isShow === false) return;
      const list = [];

      this.Flows.forEach((item) => {
        // 计算节点之间的连线
        item.Children.forEach((child) => {
          const parentDom = this.$refs[item._id][0];
          const childDom = this.$refs[child][0];

          const start = {
            x: parentDom.offsetLeft + 108,
            y: parentDom.offsetTop + 264,
          };
          const end = {
            x: childDom.offsetLeft + 108,
            y: childDom.offsetTop,
          };
          // list.push({
          //   path: `${item._id}-${item.Children}`,
          //   d: `
          //   M ${start.x} ${start.y}
          //   C ${start.x} ${start.y}
          //     ${start.x} ${start.y + 38}
          //     ${start.x} ${end.y - 67}
          //   C ${start.x} ${end.y}
          //     ${end.x} ${end.y - 38}
          //     ${end.x} ${end.y}
          //   `,
          // });
          list.push({
            path: `${item._id}-${child}`,
            d: `
            M ${start.x} ${start.y}
            C ${start.x} ${end.y}
              ${end.x} ${end.y - 38}
              ${end.x} ${end.y}
            `,
          });
        });

        // 为第一层级的节点画一条到【新的流程】按钮的连线
        if (+item.FlowLevel == 0) {
          const childDom = this.$refs[item._id][0];
          const start = {
            x: this.$refs.canvas.offsetWidth / 2,
            y: 19,
          };
          const end = {
            x: childDom.offsetLeft + 110,
            y: childDom.offsetTop,
          };
          list.push({
            path: `root-${item._id}`,
            d: `
            M ${start.x} ${start.y}
            C ${start.x} ${end.y}
              ${end.x} ${end.y - 38}
              ${end.x} ${end.y}
            `,
          });
        }
      });
      this.pathList = list;
    },
  },
  mounted() {
    console.log(this.editStatus)
    this.debouncePathList = (() => {
      let timer;
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          this.getPathList();
        }, 300);
      };
    })();
    this.debouncePathList();
  },
};
</script>

<style lang="less" scoped>
.btn-new {
  position: absolute;
  bottom: -19px;
  left: 50%;
  transform: translate(-59px, 0px);
  padding: 10px 23px;
  border: 2px solid #ffffff;
  background: #1895fb;
}
.canvas {
  position: relative;
  margin-top: -30px;
  padding-top: 80px;
  > svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .flow-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 76px;
  }
  .flow-item {
    position: relative;
    width: 220px;
    height: 264px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 24px 36px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .header {
      position: relative;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-bottom: 1px solid #b7b6b9;
      > i {
        position: absolute;
        top: 14px;
        right: 14px;
        color: #b7b6b9;
        cursor: pointer;
      }
    }
    .arr-item {
      padding: 11px 20px 13px 36px;
      .title {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 22px;
        color: #747378;
      }
      .value {
        font-size: 12px;
        line-height: 19px;
        color: #26262c;
      }
    }
    .border {
      border-bottom: 1px solid #dddddf;
    }
    .footer-wrap {
      position: absolute;
      width: 100%;
      bottom: -18px;
    }
    .btn-list {
      display: flex;
      justify-content: center;
      i {
        color: #fff;
      }
    }
    .btn-footer {
      width: 36px;
      height: 36px;
      line-height: 30px;
      margin: 0 10px;
      text-align: center;
      font-size: 16px;
      border: 2px solid #fff;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      &.new {
        background: #1895fb;
      }
      &.link {
        background: #5fcec3;
      }
      &.edit {
        background: #e6a23c;
      }
    }
  }
}
</style>
