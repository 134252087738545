<template>
  <div class="cond-item-customer">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-select
          v-model="detail.Value"
          style="width: 100%"
          :disabled="disabled"
          filterable
          placeholder="请选择或搜索"
        >
          <el-option
            v-for="item in fields"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="5" v-if="type === 'customer' && detail.Value">
        <div class="generator-code" @click="handleCopyCode">
          <i class="el-icon-arrow-left"></i>
          <i class="el-icon-arrow-right"></i>
          <span>生成埋点代码</span>
        </div>
      </el-col>
      <el-col :span="2" style="line-height: 36px">
        <i v-if="!disabled" class="el-icon-close" @click="handleRemove"></i>
      </el-col>
    </el-row>
    <el-dialog
      title="埋点代码"
      :visible.sync="dialogVisible"
      width="800px"
      :show-close="false"
      :append-to-body="true"
    >
      <div class="code-doc" style="padding: 0 20px">
        <div style="margin-bottom: 20px; font-weight: bold">
          第一步：引入 SDK
        </div>
        <div style="position: relative; padding: 20px; background: #f4f5f7">
          <el-button
            type="text"
            style="position: absolute; top: 0; right: 10px"
            ref="copy_sdk_script"
            >复制</el-button
          >
          <div>/* 在 html 的 head 标签内加入如下代码 */</div>
          <div>{{ sdkScript }}</div>
        </div>
        <div style="margin: 20px 0; font-weight: bold">第二步：加入埋点</div>
        <div style="position: relative; padding: 20px; background: #f4f5f7">
          <el-button
            type="text"
            style="position: absolute; top: 0; right: 10px"
            ref="copy_dom_script"
            >复制</el-button
          >
          <div>/* 在需要埋点的页面元素上加上鼠标点击事件监听 */</div>
          <div>
            onclick="sendBehaviorData('Customer', '{{ detail.Value }}')"
          </div>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <el-button
          type="primary"
          size="medium"
          round
          style="
            padding: 10px 23px;
            background: #1895fb;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
              0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
          "
          @click="dialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {
    type: {
      type: String,
      default: "customer",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      sdkScript: `<script src="${window.location.origin}/sdk/sdk1.0.0.js"><\/script>`,
      hasBindedCopy: false,
    };
  },
  methods: {
    handleRemove() {
      this.$emit("remove", this.detail);
    },
    handleCopyCode() {
      this.dialogVisible = true;
      if (this.hasBindedCopy === false) {
        this.hasBindedCopy = true;
        this.$nextTick(() => {
          this.bindCopyEvent();
        });
      }
    },
    bindCopyEvent() {
      const clipboardSdk = new Clipboard(this.$refs.copy_sdk_script.$el, {
        text: () => {
          return this.sdkScript;
        },
      });
      clipboardSdk.on("success", () => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
      });

      const clipboardDom = new Clipboard(this.$refs.copy_dom_script.$el, {
        text: () => {
          return `onclick="sendBehaviorData('Customer', '${this.detail.Value}')"`;
        },
      });
      clipboardDom.on("success", () => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.cond-item-customer {
  margin-bottom: 14px;
  .generator-code {
    display: inline-block;
    line-height: 36px;
    font-size: 12px;
    font-weight: 500;
    color: #0483ec;
    cursor: pointer;
  }
  .el-icon-close {
    font-weight: 500;
    color: #9a9a9e;
    cursor: pointer;
  }
}
</style>
