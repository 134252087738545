<!-- 周期性定时群发 -->
<template>
  <cond-card title="周期性定时群发">
    <el-form-item label="群发开始时间" prop="StartTime">
      <el-date-picker
        v-model="formData.StartTime"
        type="datetime"
        style="width: 332px"
        placeholder="请选择群发开始时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :disabled="disabled || formJyMuster.StartTimeStep2Zqxqf"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="群发频率" label-width="84px" class="frequency">
      <el-select
        v-model="formData.RecurringType"
        @change="handleTypeChange"
        style="width: 100px; margin-right: 16px"
        :disabled="disabled || formJyMuster.RecurringStep2Zqxqf"
      >
        <el-option label="每天" value="每天"></el-option>
        <el-option label="每周" value="每周"></el-option>
        <el-option label="每月" value="每月"></el-option>
      </el-select>

      <el-select
        v-model="formData.RecurringDay"
        v-if="formData.RecurringType !== '每天'"
        style="width: 100px; margin-right: 16px"
        :disabled="disabled || formJyMuster.RecurringStep2Zqxqf"
      >
        <el-option
          v-for="item in dayList"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        ></el-option>
      </el-select>

      <el-time-select
        v-model="formData.RecurringTime"
        :picker-options="recurring"
        placeholder="选择时间"
        style="width: 100px"
        :disabled="disabled || formJyMuster.RecurringStep2Zqxqf"
      >
      </el-time-select>
    </el-form-item>
    <el-form-item label="群发结束时间" prop="EndTime">
      <el-radio-group
        v-model="formData.RecurringEndType"
        class="endType"
        :disabled="disabled || formJyMuster.RecurringEndStep2Zqxqf"
      >
        <el-radio label="NeverEnd">永不</el-radio>
        <el-radio label="AfterCertainDate">
          <span style="margin-right: 18px">结束时间</span>
          <el-date-picker
            v-if="formData.RecurringEndType === 'AfterCertainDate'"
            v-model="formData.EndTime"
            type="datetime"
            style="width: 234px"
            placeholder="请选择群发结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="disabled || formJyMuster.RecurringEndStep2Zqxqf"
          >
          </el-date-picker>
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <cond-cdxz v-if="showCxdz"></cond-cdxz>
  </cond-card>
</template>

<script>
import { mapGetters } from "vuex";
import condCard from "@/components/cond-card";
import condCdxz from "./con-cdxz";
export default {
  components: {
    condCard,
    condCdxz,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showCxdz: {
      type: Boolean,
      default: true,
    },
    formJyMuster: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      dayList: [],
      timeList: [],
      formData: {
        StartTime: "",
        EndTime: "",
        RecurringType: "每天",
        RecurringDay: "1",
        RecurringTime: "10:00",
        RecurringEndType: "NeverEnd",
      },
    };
  },
  computed: {
    ...mapGetters({
      flowFields: "common/flowFields",
    }),
    recurring() {
      const field =
        this.flowFields.find((item) => item.APIName === "RecurringTime") || {};
      const options = field.PicklistOptions || [];
      return {
        start: options[0],
        step: "00:01",
        end: options[options.length - 1],
      };
    },
  },
  methods: {
    handleTypeChange(val) {
      if (val === "每周") {
        this.dayList = [
          {
            label: "一",
            value: "1",
          },
          {
            label: "二",
            value: "2",
          },
          {
            label: "三",
            value: "3",
          },
          {
            label: "四",
            value: "4",
          },
          {
            label: "五",
            value: "5",
          },
          {
            label: "六",
            value: "6",
          },
          {
            label: "日",
            value: "7",
          },
        ];
      } else if (val === "每月") {
        const list = [];
        for (let i = 1; i <= 31; i++) {
          list.push({
            label: "" + i,
            value: "" + i,
          });
        }
        this.dayList = list;
      }
    },
    initForm(data = {}) {
      Object.keys(data).forEach((key) => {
        if (this.formData[key] !== undefined && data[key] !== undefined) {
          this.formData[key] = data[key];
        }
      });
      this.handleTypeChange(this.formData.RecurringType);
    },
    resetForm() {
      this.formData = {
        StartTime: "",
        EndTime: "",
        RecurringType: "每天",
        RecurringDay: "1",
        RecurringTime: "10:00",
        RecurringEndType: "NeverEnd",
      };
    },
    getResult() {
      return {
        ...this.formData,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.endType {
  display: flex;
  flex-direction: column;
  /deep/ .el-radio {
    line-height: 36px;
  }
}

.error-msg {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>
