<template>
  <div
    class="radio-item"
    :class="{ active: active, disabled: disabled }"
    @click="handleClick"
  >
    <div class="icon-wrap">
      <i :class="detail.icon" />
    </div>
    <div class="content">
      <div class="name">{{ detail.name }}</div>
      <div class="desc">{{ detail.desc }}</div>
    </div>
    <div class="radio-wrap">
      <i v-if="active" class="el-icon-success" />
      <div v-else class="radio"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {return {}},
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled === true) {
        return;
      }
      this.$emit("change", this.detail);
    },
  },
};
</script>

<style lang="less" scoped>
.radio-item {
  display: flex;
  align-items: center;
  float: left;
  width: 270px;
  height: 66px;
  margin-right: 22px;
  margin-bottom: 24px;
  padding: 10px 24px;
  border: 1px solid #b7b6b9;
  border-radius: 6px;
  cursor: pointer;
  .icon-wrap {
    width: 34px;
    flex: 0 0 auto;
    font-size: 20px;
  }
  .content {
    flex: 1 1 auto;
    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }
    .desc {
      font-size: 12px;
      line-height: 19px;
    }
  }
  .radio-wrap {
    width: 20px;
    flex: 0 0 auto;
    .radio {
      width: 20px;
      height: 20px;
      border: 1px dashed #b7b6b9;
      border-radius: 50%;
    }
    .el-icon-success {
      font-size: 22px;
      color: #44c5b8;
    }
  }
}
.active {
  border: 1px solid #37b3a7;
  box-shadow: 0px 0px 5px #37b3a7;
}
.disabled {
  cursor: not-allowed;
}
</style>
