<template>
  <div>
    <el-drawer
      :visible.sync="visible"
      direction="rtl"
      :before-close="handleClose"
      :show-close="false"
      :size="1020"
      v-loading="loading"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{ type === "add" ? "另存为模板" : "编辑模板" }}</span>
        </div>
        <div class="right">
          <el-button
            type="text"
            @click="handleClose"
            style="margin-right: 10px"
            :disabled="saving"
            >取消</el-button
          >
          <el-button
            v-if="curStep === 0 && type === 'edit'"
            type="primary"
            round
            @click="handleSave('saveTemplateSimple')"
            style="margin-right: 15px"
            :loading="saving"
            >保存</el-button
          >

          <el-button
            v-if="curStep === 0 && type === 'edit'"
            :disabled="saving"
            class="editBtn"
            plain
            round
            @click="handleNext"
            >修改运营计划</el-button
          >
          <el-button
            v-if="curStep > 0 && type === 'edit'"
            type="primary"
            round
            @click="handlePre"
            >上一步</el-button
          >
          <el-button
            v-if="curStep > 0 && curStep < 3 && type === 'edit'"
            type="primary"
            round
            @click="handleNext"
            >下一步</el-button
          >
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            v-if="type === 'add' || (type === 'edit' && curStep === 3)"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="80px"
          label-position="left"
        >
          <template v-if="type === 'edit'">
            <div v-show="curStep > 0">
              <div class="step-list">
                <div class="step-item" :class="{ ready: curStep === 1 }">
                  <i class="iconfont icon-user-doublekx"></i>
                  <div>
                    <div class="title">客户筛选</div>
                    <div class="desc">设置分群名称及数据更新方式</div>
                  </div>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="step-item" :class="{ ready: curStep === 2 }">
                  <i class="iconfont icon-rili"></i>
                  <div>
                    <div class="title">触发规则</div>
                    <div class="desc">定义分群条件和逻辑</div>
                  </div>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="step-item" :class="{ ready: curStep === 3 }">
                  <i class="iconfont icon-neirong"></i>
                  <div>
                    <div class="title">渠道内容</div>
                    <div class="desc">定义触达渠道和内容</div>
                  </div>
                </div>
              </div>

              <div v-show="curStep === 1" class="step-1">
                <cond-card>
                  <el-form-item
                    label="运营计划名称"
                    prop="CampaignId"
                    style="margin: 6px 0"
                    label-width="94px"
                  >
                    <el-input
                      v-model="formData.Name"
                      placeholder="运营计划名称"
                      style="width: 440px"
                      :disabled="disabled"
                    ></el-input>
                  </el-form-item>
                </cond-card>
                <cond-card title="客户筛选">
                  <el-form-item
                    label="选择客户分群"
                    prop="CustomerGroup"
                    label-width="94px"
                  >
                    <el-select
                      v-model="formData.CustomerGroup"
                      placeholder="选择一个客户分群"
                      style="width: 440px"
                      filterable
                      :disabled="disabled"
                    >
                      <el-option
                        v-for="item in groupList"
                        :key="item._id"
                        :label="item.Name"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </cond-card>
                <cond-card title="计划类型">
                  <div class="plan-types">
                    <radio-item
                      v-for="(item, index) in planTypes"
                      :key="index"
                      :detail="item"
                      :disabled="disabled"
                      :active="formData.TriggerType === item.name"
                      @change="handleTypeChange"
                    ></radio-item>
                  </div>
                </cond-card>
              </div>
              <div v-show="curStep === 2" class="step-2">
                <cond-dcds
                  v-show="formData.TriggerType === '单次定时群发'"
                  ref="dcds"
                  :disabled="disabled"
                ></cond-dcds>
                <cond-zqxqf
                  v-show="formData.TriggerType === '周期性定时群发'"
                  ref="zqxqf"
                  :disabled="disabled"
                ></cond-zqxqf>
                <cond-dctj
                  v-show="formData.TriggerType === '单次触发'"
                  ref="dctj"
                  :disabled="disabled"
                ></cond-dctj>
                <cond-cdsj
                  v-show="formData.TriggerType === '单次触发'"
                  ref="cdsj"
                  :disabled="disabled"
                ></cond-cdsj>
                <cond-dbc
                  v-show="formData.TriggerType === '多波次流程画布'"
                  :isShow="
                    formData.TriggerType === '多波次流程画布' && curStep === 2
                  "
                  ref="dbc"
                  :disabled="disabled"
                ></cond-dbc>
              </div>
              <div v-show="curStep === 3" class="step-3">
                <cond-card
                  title="触达渠道"
                  v-if="formData.TriggerType !== '多波次流程画布'"
                >
                  <el-form-item
                    label="触达渠道"
                    prop="MessageChannel"
                    label-width="70px"
                  >
                    <el-cascader
                      v-model="formData.MessageChannel"
                      :options="MessageChannelOptions"
                      @change="handleChannelChange"
                      style="width: 320px"
                      :disabled="disabled"
                    ></el-cascader>
                  </el-form-item>
                </cond-card>
                <cond-card
                  title="触达内容"
                  v-if="formData.TriggerType !== '多波次流程画布'"
                >
                  <el-form-item
                    label="触达内容"
                    prop="MessageTemplate"
                    label-width="70px"
                  >
                    <el-select
                      v-model="formData.MessageTemplate"
                      placeholder="请选择触达内容"
                      style="width: 320px"
                      filterable
                      :disabled="disabled"
                    >
                      <el-option
                        v-for="item in MessageTemplateOptions"
                        :key="item._id"
                        :label="item.Name"
                        :value="item._id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </cond-card>
                <cond-path ref="path" :disabled="disabled"></cond-path>
              </div>
            </div>
          </template>
          <div v-show="curStep === 0">
            <el-form-item label="模板名称" prop="Name">
              <el-input
                placeholder="请输入活动模板名称"
                v-model="formData.Name"
                style="width: 465px"
              ></el-input>
            </el-form-item>
            <el-form-item label="模板分类" prop="TemplateType">
              <el-select
                placeholder="请选择模板分类"
                style="width: 465px"
                v-model="formData.TemplateType"
              >
                <el-option
                  v-for="item in picklist"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="模板封面（329*120）"
              prop="TemplateCoverPicture"
            >
              <upload
                @file="templateCoverPicture"
                :url="formData.TemplateCoverPicture"
                ref="coverPicture"
              />
            </el-form-item>
            <el-form-item label="模板描述" prop="TemplateDescription">
              <el-input
                v-model="formData.TemplateDescription"
                type="textarea"
                placeholder="请输入活动模版描述"
                style="width: 465px"
                maxlength="512"
                show-word-limit
                rows="5"
              >
              </el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { addCampaign } from "@/api/plan";

import upload from "@/components/upload.vue";
import { apiGetTemplateDetail, editCampaign } from "@/api/plan";
import { mapActions, mapGetters } from "vuex";
import { apiGetGroups } from "@/api/group";

import { apiGetTemplateListByChannel } from "@/api/template";
import radioItem from "@/components/radio-item";
import condCard from "@/components/cond-card";
import condDcds from "../../plan/components/con-dcds";
import condZqxqf from "./con-zqxqf";
import condDctj from "./con-dctj";
import condDbc from "./con-dbc";
import condCdsj from "./con-cdsj";
import condPath from "./con-path";

import { getImgUrl } from "@/api/upload";

export default {
  components: {
    upload,
    radioItem,
    condCard,
    condDcds,
    condZqxqf,
    condDctj,
    condDbc,
    condCdsj,
    condPath,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    CampaignId: null,
    type: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      saving: false,
      formData: {
        Name: "",
        TemplateType: "",
        TemplateDescription: "",
        TemplateCoverPicture: "",
        CustomerGroup: "",
        TriggerType: "单次定时群发",
        MessageChannel: [],
        MessageTemplate: "",
        Status: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入活动模板名称",
          trigger: "blur",
        },
        TemplateType: {
          required: true,
          message: "请选择模板分类",
          trigger: "blur",
        },
        TemplateDescription: {
          required: true,
          message: "请输入活动模版描述",
          trigger: "blur",
        },
        TemplateCoverPicture: {
          required: true,
          message: "请选择封面图片",
          trigger: "blur",
        },
        CustomerGroup: {
          required: true,
          message: "请选择客户分群",
          trigger: "change",
        },
        MessageChannel: {
          required: true,
          message: "请选择触达渠道",
          trigger: "change",
        },
        MessageTemplate: {
          required: true,
          message: "请选择触达内容",
          trigger: "change",
        },
      },
      loading: false,
      curStep: 0,
      disabled: false,
      groupList: [],
      planTypes: [
        {
          icon: "iconfont icon-clock",
          name: "单次定时群发",
          desc: "创建一次性执行的计划",
        },
        {
          icon: "iconfont icon-rili",
          name: "周期性定时群发",
          desc: "创建重复执行的计划",
        },
        {
          icon: "iconfont icon-lightning",
          name: "单次触发",
          desc: "创建一次性的触发型计划",
        },
        {
          icon: "iconfont icon-dashbaord",
          name: "多波次流程画布",
          desc: "使用可视化多流程来创建计划",
        },
      ],
      MessageTemplateOptions: [],
      templateShowImgUrl: "",
    };
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.resetForm();
      } else {
        this.getPlanDetail();
      }
    },
  },
  computed: {
    ...mapActions({
      getAllChannels: "common/getAllChannels",
    }),
    ...mapGetters({
      campaign: "common/campaign",
      allChannels: "common/allChannels",
      typesChannels: "common/typesChannels",
    }),
    field() {
      const name = "TemplateType";
      const field = this.campaign.find((item) => item.APIName === name);
      return field || {};
    },
    picklist() {
      if (this.field) {
        return this.field.PicklistOptions || [];
      } else {
        return [];
      }
    },
    MessageChannelOptions() {
      const channels = this.allChannels;
      const map = {};
      const result = [];
      (this.typesChannels || []).forEach((typeName) => {
        map[typeName] = {};
      });

      channels.forEach((item) => {
        if (item.IsActive == 0) {
          return;
        }
        if (
          map[item.Type] === undefined ||
          Object.keys(map[item.Type]).length === 0
        ) {
          map[item.Type] = {
            label: item.Type,
            value: item.Type,
            children: [],
          };
        }
        map[item.Type].children.push({
          label: item.Name,
          value: item._id,
        });
      });
      Object.keys(map).forEach((key) => {
        result.push(map[key]);
      });
      return result;
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleSave(editType) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === "add") {
            this.addForm();
          } else {
            if (editType === "saveTemplateSimple") {
              this.saveTemplateSimple();
            } else {
              this.submitForm();
            }
          }
        }
      });
    },
    addForm() {
      this.saving = true;
      addCampaign({
        Name: this.formData.Name,
        TemplateType: this.formData.TemplateType,
        TemplateDescription: this.formData.TemplateDescription,
        TemplateCoverPicture: this.formData.TemplateCoverPicture,
        CampaignId: this.CampaignId,
      })
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    submitForm() {
      const {
        Name,
        TemplateType,
        TemplateDescription,
        TemplateCoverPicture,
        CustomerGroup,
        TriggerType,
        MessageChannel,
        MessageTemplate,
      } = this.formData;
      const param = {
        Name,
        IsTemplate: true,
        TemplateType,
        TemplateDescription,
        TemplateCoverPicture,
        CustomerGroup,
        TriggerType,
        // Status: type === 0 ? "草稿" : "已发布",
        Flows: [],
        StatusConfigItems: this.$refs.path.getResult(),
      };
      if (param.StatusConfigItems === false) {
        return;
      }
      if (TriggerType === "单次定时群发") {
        param.Flows = [this.$refs.dcds.getResult()];
      } else if (TriggerType === "周期性定时群发") {
        param.Flows = [this.$refs.zqxqf.getResult()];
      } else if (TriggerType === "单次触发") {
        param.Flows = [
          {
            ...this.$refs.dctj.getResult(),
            ...this.$refs.cdsj.getResult(),
          },
        ];
      } else if (TriggerType === "多波次流程画布") {
        param.Flows = this.$refs.dbc.getResult();
      }
      if (TriggerType === "多波次流程画布") {
        // 多波次每个流程中有自己多渠道和内容，所以用不到外层的渠道和内容，并且页面上也不会显示
      } else {
        param.Flows.forEach((item) => {
          Object.assign(item, {
            MessageChannelType: MessageChannel[0],
            MessageChannel: MessageChannel[1],
            MessageTemplate: MessageTemplate,
          });
        });
      }

      this.saving = true;

      editCampaign(this.CampaignId, param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    // 简单模式保存
    saveTemplateSimple() {
      const param = {
        Name: this.formData.Name,
        TemplateType: this.formData.TemplateType,
        TemplateCoverPicture: this.formData.TemplateCoverPicture,
        TemplateDescription: this.formData.TemplateDescription,
      };
      this.saving = true;

      editCampaign(this.CampaignId, param)
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    templateCoverPicture(file) {
      this.formData.TemplateCoverPicture = file.url;
      this.templateShowImgUrl = file.imgUrl;
    },
    resetForm() {
      this.curStep = 0;
      this.disabled = false;
      this.formData = {
        Name: "",
        TemplateType: "",
        TemplateDescription: "",
        TemplateCoverPicture: "",
        CustomerGroup: "",
        TriggerType: "单次定时群发",
        MessageChannel: [],
        MessageTemplate: "",
      };
      this.$refs.dcds.resetForm();
      this.$refs.zqxqf.resetForm();
      this.$refs.dctj.resetForm();
      this.$refs.cdsj.resetForm();
      this.$refs.dbc.resetForm();
      this.$refs.path.resetForm();
      setTimeout(() => {
        this.$refs.form.clearValidate();
      }, 0);
    },
    getGroupList() {
      const param = {
        offset: 0,
        limit: 10000,
      };
      apiGetGroups(param).then((res) => {
        this.groupList = res.customerGroups;
      });
    },
    handleTypeChange(plan) {
      this.formData.TriggerType = plan.name;
    },
    handleChannelChange(channel) {
      this.formData.MessageTemplate = "";
      this.getTemplates(channel[1]);
    },

    getTemplates(channelId) {
      apiGetTemplateListByChannel(channelId).then((res) => {
        this.MessageTemplateOptions = res.templates || [];
      });
    },
    getPlanDetail() {
      if (this.type === "edit") {
        this.loading = true;
        apiGetTemplateDetail(this.CampaignId)
          .then((res) => {
            if (this.saveType === "templateAdd") {
              this.disabled = false;
            } else {
              this.disabled = res.Status === "已发布" ? true : false;
            }
            const flowItem = res.Flows[0] || {};

            const initData = {
              Name: res.Name,
              TemplateType: res.TemplateType,
              TemplateDescription: res.TemplateDescription,
              TemplateCoverPicture: res.TemplateCoverPicture,
              CustomerGroup: res.CustomerGroup,
              TriggerType: res.TriggerType,
              Status: res.Status,
              MessageTemplate: flowItem.MessageTemplate,
              MessageChannel: [
                flowItem.MessageChannelType,
                flowItem.MessageChannel,
              ],
            };
            if (res.TriggerType === "单次定时群发") {
              this.$refs.dcds.initForm(flowItem);
            } else if (res.TriggerType === "周期性定时群发") {
              this.$refs.zqxqf.initForm(flowItem);
            } else if (res.TriggerType === "单次触发") {
              this.$refs.dctj.initForm(flowItem);
              this.$refs.cdsj.initForm(flowItem);
            } else if (res.TriggerType === "多波次流程画布") {
              this.$refs.dbc.initForm(res.Flows || [], res.StartTime, res.EndTime);
            }
            this.$refs.path.initForm(res.StatusConfigItems || []);
            Object.assign(this.formData, initData);
            this.getTemplates(flowItem.MessageChannel);
            getImgUrl(res.TemplateCoverPicture).then((res) => {
              this.$refs.coverPicture.setFileList({
                url: initData.TemplateCoverPicture,
                img_url: res.signUrl,
                name: initData.TemplateCoverPicture,
              });
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handlePre() {
      this.curStep--;
    },
    handleNext() {
      if (this.curStep === 0) {
        let flag = false;
        this.$refs.form.validateField(
          [
            "Name",
            "TemplateType",
            "TemplateCoverPicture",
            "TemplateDescription",
          ],
          (nameErr) => {
            if (nameErr) {
              flag = true;
            }
          }
        );
        if (!flag) {
          this.curStep++;
        }
      } else if (this.curStep === 1) {
        let flag = false;
        this.$refs.form.validateField(["CustomerGroup"], (nameErr) => {
          if (nameErr) {
            flag = true;
          }
        });
        if (!flag) {
          this.curStep++;
        }
      } else {
        if (this.next2Disabled()) {
          return;
        }
        this.curStep++;
      }
    },
    next2Disabled() {
      let disabled = false;
      if (this.formData.TriggerType === "单次触发") {
        if (this.$refs.dctj) {
          disabled = !this.$refs.dctj.validate();
        }
      }
      return disabled;
    },
  },
  mounted() {
    this.getGroupList();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-drawer {
  background: #f6f7f8;
  .el-drawer__header {
    margin-bottom: 22px;
    padding: 0;
  }
  .el-drawer__body {
    padding: 0 22px;
    overflow: auto;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 30px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.06);
  .left {
    font-size: 24px;
    line-height: 32px;
    i {
      margin-right: 22px;
      cursor: pointer;
    }
  }
}
.drawer-content {
  padding: 25px 28px;
  background: #ffffff;
  /* M - 24 dp - modal */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 24px 36px rgba(0, 0, 0, 0.04),
    0px 16px 32px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.commonWidth {
  /deep/ .el-drawer {
    width: 72% !important;
  }
}
.fullWidth {
  /deep/ .el-drawer {
    width: 100% !important;
  }
}
/deep/ .el-drawer {
  background: #f6f7f8;
  .el-drawer__header {
    margin-bottom: 16px;
    padding: 0;
  }
  .el-drawer__body {
    padding: 0 30px;
    overflow: auto;
  }
}
.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding: 0 30px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 4px rgba(0, 0, 0, 0.06);
  .left {
    font-size: 24px;
    line-height: 32px;
    i {
      margin-right: 22px;
      cursor: pointer;
    }
  }
}

.step-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  .step-item {
    display: flex;
    align-items: center;
    width: 230px;
    padding: 10px 12px 10px 20px;
    background: #fff;
    border: 1px solid #b7b6b9;
    border-radius: 6px;
    &.ready {
      color: #19b977;
      border-color: #19b977;
      i {
        color: #19b977;
      }
    }
    > i {
      width: 24px;
      margin-right: 12px;
      font-size: 24px;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .desc {
      font-size: 12px;
      line-height: 19px;
    }
  }
  > i {
    font-size: 16px;
    font-weight: bold;
    margin: 0 22px;
  }
}
.cond-combination {
  .title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .content {
    padding: 20px;
    padding-bottom: 6px;
    background: #f6f7f8;
    border-radius: 6px;
  }
}
.plan-types {
  overflow: hidden;
  margin-bottom: -16px;
}

.editBtn {
  border-color: #0483ec;
  color: #0483ec;
}
</style>
